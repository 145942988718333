import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router'
import { Row } from 'react-bootstrap'
import ReactPlaceholder from 'react-placeholder/lib'
import ReactGA from 'react-ga4'

import Error from '../../components/error'
import Query from '../../components/Query'
import { Context } from '../../components/Store'
import FAIR_YEARS from '../../queries/fairs/years'
import ListFairs from './list'
import Box from '../../components/Box'
import CenterColumn from '../../components/Box/centerColumn'

const FairByYear = () => {
    const [state] = useContext(Context)
    const { year } = useParams()

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `/fairs/year/${year}` })
    })

    return (
        <Query query={FAIR_YEARS(year)} slug={null} locale={state.language}>
            {({ loading, data, err }) => {
                if (loading) {
                    return (
                        <Box>
                            <CenterColumn>
                                <Row>
                                    {[...Array(8)].map((item, index) => {
                                        return (
                                            <Row key={`loading-${index}`}>
                                                <ReactPlaceholder
                                                    className="py-5 px-5"
                                                    showLoadingAnimation={true}
                                                    type="rect"
                                                    rows={1}
                                                    ready={!loading}
                                                />
                                            </Row>
                                        )
                                    })}
                                </Row>
                            </CenterColumn>
                        </Box>
                    )
                }

                if (data) {
                    return <ListFairs data={data} year={year} />
                }

                if (err) {
                    ;<Error err={err} />
                }
            }}
        </Query>
    )
}

export default FairByYear
