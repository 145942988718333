import React, { useContext, useEffect } from 'react'
import { Row } from 'react-bootstrap'
import { useParams } from 'react-router'
import ReactPlaceholder from 'react-placeholder'
import { SRLWrapper } from 'simple-react-lightbox'
import ReactGA from 'react-ga4'

import { Context } from '../../components/Store'
import Query from '../../components/Query'
import ARTWORK from '../../queries/artwork'
import Error from '../../components/error'
import Box from '../../components/Box'
import CenterColumn from '../../components/Box/centerColumn'
import Text from '../../components/text'
import Artwork from '../../components/artwork'
import Title from '../../components/Texts/title'
import SubTitle from '../../components/Texts/subtitle'
import Hr from '../../components/hr'
import GoBack from '../../components/back'
import Seo from '../../components/Seo'
import lightBoxConfig from '../../utils/lightBoxConfig'

const ArtworkSeries = () => {
    const [state] = useContext(Context)
    const { year, artist, id, slug } = useParams()

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: `/artwork/${artist}/${id}/${year}/${slug}`,
        })
    })

    return (
        <Query
            query={ARTWORK(year, artist, id)}
            slug={slug}
            locale={state.language}
        >
            {({ loading, data, err }) => {
                if (loading) {
                    return (
                        <Box>
                            <CenterColumn>
                                <ReactPlaceholder
                                    className="py-5 px-5 mb-4"
                                    showLoadingAnimation={true}
                                    type="text"
                                    rows={20}
                                    ready={!loading}
                                />
                            </CenterColumn>
                        </Box>
                    )
                }

                if (data) {
                    return (
                        <Box>
                            <CenterColumn>
                                {data.artwork.map((item) => {
                                    return (
                                        <React.Fragment
                                            key={`series-${item.id}`}
                                        >
                                            <Seo
                                                title={`SGR Galería - ${item.title}`}
                                            />

                                            <GoBack />

                                            <Title text={item.title} />
                                            {item.year ? (
                                                <SubTitle
                                                    text={item.year.toString()}
                                                />
                                            ) : null}

                                            <Hr />

                                            {item.info ? (
                                                <Text data={item.info} />
                                            ) : null}

                                            <Row className="mt-5">
                                                <SRLWrapper
                                                    options={lightBoxConfig}
                                                >
                                                    {item.pieces ? (
                                                        <>
                                                            {item.pieces.map(
                                                                (piece) => {
                                                                    return (
                                                                        <Artwork
                                                                            key={`piece-${piece.id}`}
                                                                            data={
                                                                                piece
                                                                            }
                                                                        />
                                                                    )
                                                                }
                                                            )}
                                                        </>
                                                    ) : null}
                                                </SRLWrapper>
                                            </Row>
                                        </React.Fragment>
                                    )
                                })}
                            </CenterColumn>
                        </Box>
                    )
                }

                if (err) {
                    ;<Error err={err} />
                }
            }}
        </Query>
    )
}

export default ArtworkSeries
