import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
    link: (props) => ({
        textDecoration: 'none',
        fontSize: `${props.fontSize ? props.fontSize : '1rem'}`,
        '&:hover': {
            color: theme.secondaryColor,
        },
    }),
    main: (props) => ({
        color: `${props.color ? props.color : theme.blue}`,
        fontFamily: `${props.font ? props.font : theme.fontBold}`,
    }),
    projects: (props) => ({
        color: `${props.color ? props.color : theme.red}`,
        fontFamily: `${props.font ? props.font : theme.fontBold}`,
    }),
}))

const ArtistsList = ({ sgr, guests, room, styles }) => {
    const classes = useStyles(styles)

    // create a new array with all artists
    let allArtists = []

    // concat only if artists array exist in props
    if (sgr) {
        allArtists = allArtists.concat(sgr)
    }
    if (guests) {
        allArtists = allArtists.concat(guests)
    }

    if (allArtists.length > 0) {
        return (
            <>
                {allArtists.map((item, index) => {
                    // create Link for sgr artists and artfairs
                    if (item.__typename === 'Artist') {
                        return (
                            <React.Fragment
                                key={`sgr-${item.__typename}-${item.id}`}
                            >
                                <Link
                                    to={`/artists/${item.slug}`}
                                    className={`${classes.link} ${
                                        room === 'Exposiciones'
                                            ? classes.main
                                            : classes.projects
                                    }`}
                                >
                                    {item.name}
                                </Link>
                                {/* insert , if need it */}
                                {index < allArtists.length - 1 ? (
                                    <span
                                        className={`${
                                            room === 'Exposiciones'
                                                ? classes.main
                                                : classes.projects
                                        }`}
                                    >
                                        ,
                                    </span>
                                ) : null}
                            </React.Fragment>
                        )
                    } else {
                        // is a guest artist render it with no link
                        return (
                            <React.Fragment
                                key={`guest-${item.__typename}-${item.id}`}
                            >
                                <span
                                    className={`${classes.link} ${
                                        room === 'Exposiciones'
                                            ? classes.main
                                            : classes.projects
                                    }`}
                                >
                                    {item.name}
                                </span>
                                <span
                                    className={`${
                                        room === 'Exposiciones'
                                            ? classes.main
                                            : classes.projects
                                    }`}
                                >
                                    {index < allArtists.length - 1
                                        ? ', '
                                        : null}
                                </span>
                            </React.Fragment>
                        )
                    }
                })}
            </>
        )
    }
}

ArtistsList.propTypes = {
    sgr: PropTypes.object,
    guests: PropTypes.object,
    room: PropTypes.string,
    styles: PropTypes.object,
}

ArtistsList.defaultProps = {
    styles: {
        color: '',
        font: '',
        fontSize: '1rem',
    },
}

export default ArtistsList
