import gql from 'graphql-tag'

const EXHIBTION_YEAR = (year) => {
    const nextYear = parseInt(year) + 1
    const open = `${year}-01-01T17:14:17.169Z`
    const close = `${nextYear.toString()}-02-28T17:14:17.169Z`

    const query = gql`
        fragment ExhibitionData on Exhibition {
            id
            title
            subtitle
            slug
            room
            open
            close
            cover {
                id
                url
            }
            sgr_artists (sort: "name:DESC"){
                id
                slug
                name
            }
            guest_artists (sort: "name:DESC"){
                id
                name
            }
        }

        query Year {
            exhibitions(locale: "es-CO", sort: "open:DESC") {
                open
            },
            exposiciones: exhibitions(
                sort:"open:DESC", 
                where: {room: "Exposiciones", open_gte:"${open}", close_lte: "${close}"}
                ){
                ...ExhibitionData
            }
            proyectos: exhibitions(
                sort:"open:DESC", 
                where: {room: "Proyectos", open_gte:"${open}", close_lte: "${close}"}
                ){
                ...ExhibitionData
            }
        }
    `
    return query
}

export default EXHIBTION_YEAR
