import React from 'react'
import PropTypes from 'prop-types'
import Output from 'editorjs-react-renderer'
import Row from 'react-bootstrap/Row'
import { createUseStyles } from 'react-jss'

import injectMediaUrl from '../../utils/injectMediaUrl'

const useStyles = createUseStyles((theme) => ({
    bio: {
        textAlign: 'justify',
        color: theme.colorPrimary,
        fontFamily: `${theme.fontLight} !important`,
    },
}))

const editorConfig = {
    header: {
        disableDefaultStyle: true,
    },
    paragraph: {
        disableDefaultStyle: true,
    },
    list: {
        disableDefaultStyle: true,
    },
    quote: {
        disableDefaultStyle: true,
    },
}

const Text = ({ data }) => {
    const classes = useStyles()

    return (
        <Row className={classes.bio}>
            <Output
                data={injectMediaUrl(JSON.parse(data))}
                config={editorConfig}
            />
        </Row>
    )
}

Text.propTypes = {
    data: PropTypes.string,
}

export default Text
