import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

import { Context } from './Store'

const useStyles = createUseStyles((theme) => ({
    room: (props) => ({
        fontFamily: `${props.font ? props.font : theme.fontRegular}`,
        fontSize: `${props.fontSize ? props.fontSize : '1rem'}`,
        color: `${props.color ? props.color : theme.colorPrimary}`,
    }),
}))

const BuildRoom = (props) => {
    const [state] = useContext(Context)
    const classes = useStyles(props.styles)

    let room = ''

    if (props.room === 'Exposiciones') {
        room = `${
            state.language === 'es-CO'
                ? 'Sala de exposiciones'
                : 'Exhibition room'
        }`
    }

    if (props.room === 'Proyectos') {
        room = `${
            state.language === 'es-CO' ? 'Sala de Proyectos' : 'Projects room'
        }`
    }

    return <span className={`${classes.room} ${props.className}`}>{room}</span>
}

BuildRoom.propTypes = {
    room: PropTypes.string,
    className: PropTypes.string,
    styles: PropTypes.object,
}

BuildRoom.defaultProps = {
    styles: {
        font: '',
        fontSize: '1rem',
        color: '',
    },
}

export default BuildRoom
