import gql from 'graphql-tag'

const FAIR_YEARS = (year) => {
    const open = `${year}-01-01T17:14:17.169Z`
    const close = `${year}-12-31T17:14:17.169Z`

    const query = gql`
        query Years{
            dates: artFairs {
                open
            }
            artFairs(sort: "open:DESC", where: {open_gte:"${open}", close_lte: "${close}"}) {
                id
                slug
                fair
                open
                close
                cover {
                    id
                    url
                    formats
                }
            }
        }
    `
    return query
}

export default FAIR_YEARS
