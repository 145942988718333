import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Col } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'

import ShimmerImage from '../Image'

const useStyles = createUseStyles((theme) => ({
    box: {
        textAlign: 'center',
        '& a': {
            color: '#4F4F4F',
            textDecoration: 'none',
            '&:hover': {
                color: theme.textGray,
            },
        },
    },
    title: {
        fontFamily: theme.fontRegular,
        fontSize: '1.65rem',
    },
    subtitle: {
        fontFamily: theme.fontLight,
        fontSize: '1.375rem',
    },
    year: {
        fontFamily: theme.fontLight,
        fontSize: '1rem',
    },
    hr: {
        margin: '2% 45% 5%',
        borderTop: '0.1rem solid #000000',
    },
}))

const Gridbox = (props) => {
    const classes = useStyles()

    return (
        <Col
            xs={12}
            md={props.span}
            className={`${classes.box} ${props.className}`}
        >
            <Link to={`${props.to}/${props.data.content.slug}`}>
                {/* render artists grid data */}
                {props.data.type === 'artist' ? (
                    <>
                        <ShimmerImage
                            src={props.data.content.picture.formats.small.url}
                        />
                        <h1 className={`${classes.title} mt-4`}>
                            {props.data.content.name}
                        </h1>
                        <h3 className={`${classes.subtitle}`}>
                            {props.data.content.birthyear}
                            {props.data.content.deathyear
                                ? `-${props.data.content.deathyear}`
                                : null}
                            . {props.data.content.birthcity},{' '}
                            {props.data.content.birthcountry}
                        </h3>
                        <hr className={classes.hr} />
                    </>
                ) : null}

                {/* render artwork series */}
                {props.data.type === 'artwork' ? (
                    <>
                        <ShimmerImage
                            src={props.data.content.cover.formats.small.url}
                        />
                        <h3 className={`${classes.subtitle} mt-3`}>
                            {props.data.content.title}
                        </h3>
                        <h4 className={`${classes.year}`}>
                            {props.data.content.year ? (
                                <>({props.data.content.year})</>
                            ) : null}
                        </h4>
                    </>
                ) : null}
            </Link>
        </Col>
    )
}

Gridbox.propTypes = {
    data: PropTypes.object,
    span: PropTypes.number,
    to: PropTypes.string,
    className: PropTypes.string,
}

Gridbox.defaultProps = {
    data: {
        picture: { url: 'https://placekitten.com/640/405' },
    },
    span: 4,
    className: '',
}

export default Gridbox
