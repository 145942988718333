import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider } from '@apollo/react-hooks'
import client from './utils/apolloClient'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ThemeProvider } from 'react-jss'
import SimpleReactLightbox from 'simple-react-lightbox'

import './static/fonts/DINNextLTPro-Bold/DINNextLTPro-Bold.css'
import './static/fonts/DINNextLTPro-Regular/DINNextLTPro-Regular.css'
import './static/fonts/DINNextLTPro-Light/DINNextLTPro-Light.css'
import './static/fonts/DINNextLTPro-Medium/DINNextLTPro-Medium.css'
import './static/fonts/DINNextLTPro-Italic/DINNextLTPro-Italic.css'

// react jss theme

const theme = {
    fontRegular: 'DINNextLTPro-Regular',
    fontBold: 'DINNextLTPro-Bold',
    fontLight: 'DINNextLTPro-Light',
    fontMedium: 'DINNextLTPro-Medium',
    fontItalic: 'DINNextLTPro-Italic',
    colorPrimary: '#292929',
    secondaryColor: '#A3A3A3',
    textGray: '#868686',
    h1: '#1A1A1A',
    blue: '#7399CA',
    red: '#FF635F',
}

ReactDOM.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <ThemeProvider theme={theme}>
                <SimpleReactLightbox>
                    <App />
                </SimpleReactLightbox>
            </ThemeProvider>
        </ApolloProvider>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
