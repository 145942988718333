import React from 'react'
import PropTypes from 'prop-types'
import Ratio from 'react-bootstrap/ratio'

const Oembed = (props) => {
    const data = JSON.parse(props.data)
    let src = ''

    if (data.mime === 'video/youtube') {
        const code = data.url.split('https://www.youtube.com/watch?v=')
        src = `https://youtube.com/embed/${code[1]}?feature=oembed`
    }
    return (
        <Ratio aspectRatio="16x9">
            <iframe
                src={src}
                width={props.size.width}
                height={props.size.height}
                frameBorder="0"
            ></iframe>
        </Ratio>
    )
}

Oembed.propTypes = {
    data: PropTypes.string,
    size: PropTypes.object,
}

Oembed.defaultProps = {
    size: {
        width: 200,
        height: 113,
    },
}

export default Oembed
