import { useQuery } from '@apollo/react-hooks'

// eslint-disable-next-line react/prop-types
const Query = ({ children, query, slug, locale }) => {
    const { data, loading, error } = useQuery(query, {
        variables: { slug: slug, locale: locale },
    })

    return children({ data, loading, error })
}

export default Query
