import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment/min/moment-with-locales'
import Moment from 'react-moment'
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'

import buildMediaUrl from '../../utils/buildMediaUrl'
import Box from '../Box'
import CenterColumn from '../Box/centerColumn'
import Title from '../Texts/title'
import SubTitle from '../Texts/subtitle'
import Hr from '../hr'
import CardImage from '../cardPicture'
import Gridbox from '../gridBox'
import { Context } from '../../components/Store'
import ShimmerImage from '../Image'
import lightBoxConfig from '../../utils/lightBoxConfig'
import { SRLWrapper } from 'simple-react-lightbox'

const useStyles = createUseStyles((theme) => ({
    openClose: {
        fontFamily: theme.fontRegular,
        fontSize: '1.2rem',
        color: '#606060',
    },
    bold: {
        fontFamily: theme.fontBold,
        fontSize: '1.1rem',
    },
    regular: {
        fontFamily: theme.fontRegular,
        fontSize: '1.1rem',
    },
    artist: {
        '& a': {
            color: '#4F4F4F',
            textDecoration: 'none',
            fontSize: '1.4rem',
            '&:hover': {
                color: '#303030',
            },
        },
        '& span': {
            color: '#4F4F4F',
            textDecoration: 'none',
            fontSize: '1.4rem',
            '&:hover': {
                color: '#303030',
            },
        },
    },
    press: {
        '& ul': {
            padding: 0,
            '& li': {
                listStyle: 'none',
                '& a': {
                    fontFamily: theme.fontRegular,
                    textDecoration: 'none',
                    color: '#606060',
                    '&:hover': {
                        color: '#4F4F4F',
                    },
                },
            },
        },
    },
}))

const DisplayFair = ({ data }) => {
    const [state] = useContext(Context)
    const classes = useStyles()
    // Sets the moment instance to use.
    Moment.globalMoment = moment
    // Set the locale for every react-moment instance to French.
    Moment.globalLocale = state.language

    return (
        <Container fluid>
            <Row>
                <CardImage
                    src={buildMediaUrl(data.cover.url)}
                    span={12}
                    height={`300px`}
                />
            </Row>
            <Box>
                <CenterColumn>
                    <Title text={data.fair} />
                    {data.location ? <SubTitle text={data.location} /> : null}
                    <Hr />
                    <Row className="text-center mb-5">
                        <Col className={classes.openClose}>
                            <Moment date={data.open} format="MMMM YYYY" />
                        </Col>
                    </Row>
                    <Row className="mb-5">
                        {data.artists.map((item, index) => {
                            return (
                                <Col
                                    md={4}
                                    key={`artist-${item.id}`}
                                    className={classes.artist}
                                >
                                    <Link
                                        to={`/artists/${item.slug}`}
                                        className={`${
                                            index % 2 === 0
                                                ? classes.regular
                                                : classes.bold
                                        }`}
                                    >
                                        {item.name}
                                    </Link>
                                </Col>
                            )
                        })}
                        {data.artistas_invitados.map((item, index) => {
                            return (
                                <Col
                                    md={4}
                                    key={`guest-artist-${item.id}`}
                                    className={classes.artist}
                                >
                                    <span
                                        className={`${
                                            index % 2 === 0
                                                ? classes.regular
                                                : classes.bold
                                        }`}
                                    >
                                        {item.name}
                                    </span>
                                </Col>
                            )
                        })}
                    </Row>
                    <Row className="my-4">
                        <Col className={classes.press}>
                            {data.press.length ? (
                                <>
                                    <h5 className={classes.bold}>
                                        {state.language === 'es-CO'
                                            ? 'PRENSA'
                                            : 'PRESS'}
                                    </h5>
                                    <ul>
                                        {data.press.map((item) => {
                                            return (
                                                <li key={`press-${item.id}`}>
                                                    <a
                                                        href={item.url}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {item.title}
                                                    </a>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </>
                            ) : null}
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col>
                            {data.booth.length ? (
                                <SRLWrapper options={lightBoxConfig}>
                                    {data.booth.map((item) => {
                                        return (
                                            <ShimmerImage
                                                key={`picture-${item.id}`}
                                                src={item.formats.large.url}
                                                className="mb-3"
                                            />
                                        )
                                    })}
                                </SRLWrapper>
                            ) : null}
                        </Col>
                    </Row>
                    <Row className="my-5">
                        {data.artworks.length ? (
                            <>
                                {data.artworks.map((item) => {
                                    return (
                                        <Gridbox
                                            key={`artwork-series-${item.id}`}
                                            data={{
                                                type: 'artwork',
                                                content: item,
                                            }}
                                            to="/artwork"
                                            span={4}
                                            className="mb-4"
                                        />
                                    )
                                })}
                            </>
                        ) : null}
                    </Row>
                </CenterColumn>
            </Box>
        </Container>
    )
}

DisplayFair.propTypes = {
    data: PropTypes.object,
}

export default DisplayFair
