import gql from 'graphql-tag'

const ARTWORK = (year, artist, id) => {
    console.log(artist)
    const query = gql`
        query Artwork($slug: String!, $locale: String!) {
            artwork: artworkSeries(
                locale: $locale
                where: { slug: $slug, year: ${year}, artistas: ${id} }
            ) {
                id
                title
                info
                year
                pieces: Piece {
                    id
                    title
                    technique
                    dimension
                    year
                    video
                    images {
                        id
                        url
                        formats
                    }
                }
            }
        }
    `

    return query
}

export default ARTWORK
