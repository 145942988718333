import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router'
import { Row } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'
import ReactPlaceholder from 'react-placeholder'
import moment from 'moment/min/moment-with-locales'
import Moment from 'react-moment'
import ReactGA from 'react-ga4'

import { Context } from '../../components/Store'
import Query from '../../components/Query'
import POST from '../../queries/blog/post'
import Error from '../../components/error'
import Box from '../../components/Box'
import Text from '../../components/text'
import CenterColumn from '../../components/Box/centerColumn'
import SubTitle from '../../components/Texts/subtitle'
import Hr from '../../components/hr'
import GoBack from '../../components/back'
import Seo from '../../components/Seo'
import ShimmerImage from '../../components/Image'

const useStyles = createUseStyles((theme) => ({
    footNote: {
        fontFamily: theme.fontPrimary,
        color: '#606060',
    },
    date: {
        fontFamily: `${theme.fontLight} !important`,
        textTransform: 'capitalize',
    },
}))

const Post = () => {
    const [state] = useContext(Context)
    const { slug } = useParams()
    const classes = useStyles()
    // Sets the moment instance to use.
    Moment.globalMoment = moment
    // Set the locale for every react-moment instance to French.
    Moment.globalLocale = state.language

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `/blog/${slug}` })
    })

    return (
        <Query query={POST} slug={slug} locale={state.language}>
            {({ loading, data, err }) => {
                if (loading) {
                    return (
                        <Box>
                            <CenterColumn>
                                <ReactPlaceholder
                                    className="py-5 px-5 mb-4"
                                    showLoadingAnimation={true}
                                    type="text"
                                    rows={20}
                                    ready={!loading}
                                />
                            </CenterColumn>
                        </Box>
                    )
                }

                if (data) {
                    return (
                        <Box>
                            <CenterColumn>
                                <GoBack />
                                {data.post.map((item) => {
                                    return (
                                        <React.Fragment key={`post-${item.id}`}>
                                            <Seo
                                                title={`SGR Galería - ${item.title}`}
                                            />
                                            <Row className="pe-4 mb-5 mt-4">
                                                <ShimmerImage
                                                    src={
                                                        item.cover.formats.large
                                                            .url
                                                    }
                                                    fluid
                                                />
                                                {item.image_footnote ? (
                                                    <div
                                                        className={`${classes.footNote} mt-2`}
                                                    >
                                                        {item.image_footnote}
                                                    </div>
                                                ) : null}
                                            </Row>
                                            <SubTitle text={item.title} />
                                            <SubTitle text={item.subtitle} />
                                            {item.author ? (
                                                <SubTitle text={item.author} />
                                            ) : null}
                                            <Hr />
                                            <Row className="mb-5">
                                                <Moment
                                                    date={item.date}
                                                    format="MMMM DD YYYY"
                                                    className={`mb-3 ${classes.date}`}
                                                />
                                                <Text data={item.content} />
                                            </Row>
                                        </React.Fragment>
                                    )
                                })}
                            </CenterColumn>
                        </Box>
                    )
                }

                if (err) {
                    return <Error err={err} />
                }
            }}
        </Query>
    )
}

export default Post
