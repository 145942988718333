import gql from 'graphql-tag'

const HOME = (today) => {
    const query = gql`
        fragment ExhibitionData on Exhibition {
            id
                title
                subtitle
                slug
                    room
                    open
                    close
                    cover {
                    id
                    url
                }
                    sgr_artists (sort: "name:DESC"){
                    id
                    slug
                    name
                }
                    guest_artists (sort: "name:DESC"){
                    id
                    name
                }
        }
        query Home($locale: String!) {
            currentMain: exhibitions(
                locale: $locale,
                limit: 1,
                sort: "open:DESC",
                where: {
                    _or: [
                        {room: "Exposiciones", open_lte: "${today}", close_gte: "${today}" }, 
                        {room: "Exposiciones", close_lte: "${today}"},
                        {room: "Exposiciones", open_gte: "${today}"}
                    ]
                }
            ){
                ...ExhibitionData
            },
            currentProjects: exhibitions(
                locale: $locale,
                limit: 1,
                sort: "open:DESC",
                where: {
                    _or: [
                        {room: "Proyectos", open_lte: "${today}", close_gte: "${today}" }, 
                        {room: "Proyectos", close_lte: "${today}"},
                        {room: "Proyectos", open_gte: "${today}"}
                    ]
                }
            ){
                ...ExhibitionData
            }
            focus: exhibitions(
                locale: $locale,
                limit: 1,
                sort: "open:DESC",
                where: {
                    _or: [
                        {room: "Focus", open_lte: "${today}", close_gte: "${today}" }, 
                        
                    ]
                }
            ){
                ...ExhibitionData
            }
            currentFair: artFairs(
                limit: 1,
                 sort: "open:DESC",
                 where: {
                     _or: [
                         {open_lte: "${today}", close_gte: "${today}" }, 
                         {close_lte: "${today}"}
                     ]
                 }
            ){
               id
               slug
               fair
               open
               close
               cover{
                 id
                 url
               }
            }
            currentPromo: sgrpromos(limit: 1, sort: "start:DESC"){
                id
                title
                artist
                start
                end
                cover{ url }
                pdf {url}
            }
            artshops{
                id
                title
                url
                image{
                  id
                  url
                }
                artista{
                  id
                  name
                  slug
                }
                artista_invitado {
                  id
                  name
                }
            }
            breakingNew (locale: $locale){
                title
                subtitle
                text_1
                text_2
                link
                tagline
                cover { url }

            }
            
        }
    `
    return query
}

export default HOME
