import gql from 'graphql-tag'

const FAIR = gql`
    query Fair($slug: String!) {
        artFairs(where: { slug: $slug }) {
            id
            fair
            slug
            open
            close
            location
            cover {
                id
                url
            }
            press {
                id
                title
                url
            }
            booth {
                id
                url
                formats
            }
            artists {
                id
                slug
                name
            }
            artistas_invitados {
                id
                name
            }
            artworks {
                id
                slug
                cover {
                    id
                    url
                    formats
                }
            }
        }
    }
`
export default FAIR
