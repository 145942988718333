import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Container, Row } from 'react-bootstrap'
import ReactPlaceholder from 'react-placeholder/lib'
import ReactGA from 'react-ga4'

import { Context } from '../../components/Store'
import Query from '../../components/Query'
import EXHIBITION_YEAR from '../../queries/exhibition/year'
import Error from '../../components/error'
import Years from '../../components/Years'
import ExhibitionList from '../../components/Exhibition/list'

const ExhibitionsByYear = () => {
    const [state] = useContext(Context)
    const { year } = useParams()

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `/exhibitions/year/${year}` })
    })

    return (
        <>
            <Query
                query={EXHIBITION_YEAR(year)}
                slug={null}
                locale={state.language}
            >
                {({ loading, data, err }) => {
                    if (loading) {
                        return (
                            <Container fluid>
                                <Row>
                                    <ReactPlaceholder
                                        className="py-5 px-5 mb-3"
                                        showLoadingAnimation={true}
                                        type="rect"
                                        rows={1}
                                        ready={!loading}
                                    />
                                </Row>
                                {[...Array(6)].map((item, index) => {
                                    return (
                                        <Row
                                            key={`loading-${index}`}
                                            className="mb-3"
                                        >
                                            <ReactPlaceholder
                                                className="py-5 px-5"
                                                showLoadingAnimation={true}
                                                type="rect"
                                                rows={1}
                                                ready={!loading}
                                            />
                                        </Row>
                                    )
                                })}
                            </Container>
                        )
                    }
                    if (data) {
                        // empty array for final list
                        const list = []
                        let i,
                            l = Math.min(
                                data.exposiciones?.length,
                                data.proyectos?.length
                            )
                        // concatenate exhibitions and proyectos arrays
                        // alternating between elements
                        for (i = 0; i < l; i++) {
                            list.push(data.exposiciones[i], data.proyectos[i])
                        }
                        list.push(
                            ...data.exposiciones.slice(l),
                            ...data.proyectos.slice(l)
                        )

                        return (
                            <>
                                <Years
                                    list={data.exhibitions}
                                    type="exhibitions"
                                    current={year}
                                />
                                {list.map((item, index) => {
                                    return (
                                        <>
                                            {index % 2 === 0 ? (
                                                <ExhibitionList
                                                    key={`exhibition-${item.id}`}
                                                    data={item}
                                                    title="left"
                                                    styles={{
                                                        bgColor: '#000000',
                                                        txtColor: '#FFFFFF',
                                                    }}
                                                />
                                            ) : (
                                                <ExhibitionList
                                                    key={`exhibition-${item.id}`}
                                                    data={item}
                                                    title="right"
                                                    styles={{
                                                        bgColor: '#FFFFFF',
                                                        txtColor: '#000000',
                                                    }}
                                                />
                                            )}
                                        </>
                                    )
                                })}
                            </>
                        )
                    }
                    if (err) {
                        return <Error err={err} />
                    }
                }}
            </Query>
        </>
    )
}

export default ExhibitionsByYear
