import gql from 'graphql-tag'

const MENU_QUERY = () => {
    const query = gql`
        query Menus($locale: String!) {
            menus(locale: $locale, sort: "order:ASC", where: { active: true }) {
                id
                title
                url
                order
                external
            }
            socialMedia {
                instagram
                facebook
                whatsapp
            }
        }
    `
    return query
}

export default MENU_QUERY
