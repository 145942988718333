import React from 'react'
import PropTypes from 'prop-types'
import Row from 'react-bootstrap/Row'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
    subtitle: (props) => ({
        textAlign: props.align,
        color: '#606060',
        '& h3': {
            fontFamily: theme.fontLight,
            fontSize: '2.5rem',
        },
    }),
}))

const SubTitle = (props) => {
    const classes = useStyles(props)

    return (
        <Row className={classes.subtitle}>
            <h3>{props.text}</h3>
        </Row>
    )
}

SubTitle.propTypes = {
    text: PropTypes.string,
    align: PropTypes.string,
}

SubTitle.defaultProps = {
    align: 'center',
}

export default SubTitle
