import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Col } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
    link: {
        textDecoration: 'none',
        '&:hover': {
            color: theme.secondaryColor,
        },
    },
    textColor: (props) => ({
        color: `${props.txtColor}`,
    }),
    align: (props) => ({
        textAlign: `${props.align ? props.align : 'left'}`,
    }),
    title: {
        fontFamily: theme.fontBold,
        fontSize: '2rem',
        '&:hover': {
            color: theme.secondaryColor,
        },
    },
    subtitle: {
        fontFamily: theme.fontBold,
        fontSize: '1.5rem',
        '&:hover': {
            color: theme.secondaryColor,
        },
    },
    main: {
        color: theme.blue,
        fontFamily: theme.fontBold,
    },
    projects: {
        color: theme.red,
        fontFamily: theme.fontBold,
    },
}))

const Title = ({ data, styles }) => {
    const classes = useStyles(styles)

    return (
        <Col xs={12} md={6} className={classes.align}>
            {/* render exhibition title */}
            <Link to={`/exhibitions/${data.slug}`} className={classes.link}>
                <h1 className={`${classes.title} ${classes.textColor}`}>
                    {data.title}
                </h1>
            </Link>
            {/* subtitle */}
            {data.subtitle ? (
                <Link to={`/exhibitions/${data.slug}`} className={classes.link}>
                    <h3 className={`${classes.subtitle} ${classes.textColor}`}>
                        {data.subtitle}
                    </h3>
                </Link>
            ) : null}

            {(() => {
                // create a new array with all artists
                let allArtists = []

                // concat only if artists array exist in props
                if (data.sgr_artists) {
                    allArtists = allArtists.concat(data.sgr_artists)
                }
                if (data.guest_artists.length > 0) {
                    allArtists = allArtists.concat(data.guest_artists)
                }

                if (allArtists.length > 0) {
                    return (
                        <>
                            {allArtists.map((item, index) => {
                                // create Link for sgr artists and artfairs
                                if (
                                    item.__typename !== undefined &&
                                    item.__typename === 'Artist'
                                ) {
                                    return (
                                        <React.Fragment
                                            key={`sgr-${item.__typename}-${item.id}`}
                                        >
                                            <Link
                                                to={`/artists/${item.slug}`}
                                                className={`${classes.link} ${
                                                    data.room === 'Exposiciones'
                                                        ? classes.main
                                                        : classes.projects
                                                }`}
                                            >
                                                {item.name}
                                            </Link>
                                            {/* insert , if need it */}
                                            <span
                                                className={`${
                                                    data.room === 'Exposiciones'
                                                        ? classes.main
                                                        : classes.projects
                                                }`}
                                            >
                                                {index < allArtists.length - 1
                                                    ? ', '
                                                    : null}
                                            </span>
                                        </React.Fragment>
                                    )
                                } else {
                                    // is a guest artist render it with no link
                                    return (
                                        <React.Fragment
                                            key={`guest-${item.__typename}-${item.id}`}
                                        >
                                            <span
                                                className={`${
                                                    data.room === 'Exposiciones'
                                                        ? classes.main
                                                        : classes.projects
                                                }`}
                                            >
                                                {item.name}
                                            </span>
                                            <span
                                                className={`${
                                                    data.room === 'Exposiciones'
                                                        ? classes.main
                                                        : classes.projects
                                                }`}
                                            >
                                                {index < allArtists.length - 1
                                                    ? ', '
                                                    : null}
                                            </span>
                                        </React.Fragment>
                                    )
                                }
                            })}
                        </>
                    )
                }
            })()}
        </Col>
    )
}

Title.propTypes = {
    data: PropTypes.object,
    styles: PropTypes.object,
}

export default Title
