import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { Context } from './Store'

const Localization = ({ en, es }) => {
    const [state] = useContext(Context)

    return (
        <>
            {state.language === 'en' ? <span>{en}</span> : null}

            {state.language === 'es-CO' ? <span>{es}</span> : null}
        </>
    )
}

Localization.propTypes = {
    en: PropTypes.string,
    es: PropTypes.string,
}

export default Localization
