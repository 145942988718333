import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'
import moment from 'moment/min/moment-with-locales'
import Moment from 'react-moment'

import { Context } from '../../components/Store'

const useStyles = createUseStyles((theme) => ({
    col: (props) => ({
        paddingLeft: '2.7rem',
        paddingRight: '2.7rem',
        textAlign: props.textAlign,
        backgroundColor: props.bgColor,
    }),
    link: {
        textDecoration: 'none',
        '&:hover': {
            color: theme.secondaryColor,
        },
    },
    title: {
        fontFamily: theme.fontBold,
        fontSize: '2rem',
        '&:hover': {
            color: theme.secondaryColor,
        },
    },
    textColor: (props) => ({
        color: `${props.txtColor}`,
    }),
    subtitle: {
        fontFamily: theme.fontBold,
        fontSize: '1.5rem',
        '&:hover': {
            color: theme.secondaryColor,
        },
    },
    room: {
        fontFamily: theme.fontRegular,
    },
    blue: {
        color: `${theme.blue} !important`,
    },
    red: {
        color: `${theme.red} !important`,
    },
    lightGray: {
        color: `#606060 !important`,
    },
    darkGray: {
        color: `#4F4F4F`,
    },
    date: {
        fontFamily: theme.fontRegular,
        fontSize: '1.2rem',
    },
    artists: {
        fontFamily: theme.fontBold,
        fontSize: '1.4rem',
    },
}))

const CardInfo = (props) => {
    const [state] = useContext(Context)
    const classes = useStyles(props)
    // Sets the moment instance to use.
    Moment.globalMoment = moment
    // Set the locale for every react-moment instance to French.
    Moment.globalLocale = state.language

    return (
        <Col md={4} className={`${classes.col} py-4`}>
            <Row>
                {/* render title if target included in props insert target and rel values */}
                {props.target ? (
                    <a
                        href={props.titleTo}
                        target={props.target}
                        rel="noopener noreferrer"
                        className={classes.link}
                    >
                        <h1 className={`${classes.title} ${classes.textColor}`}>
                            {props.title}
                        </h1>
                    </a>
                ) : (
                    <Link to={props.titleTo} className={classes.link}>
                        <h1 className={`${classes.title} ${classes.textColor}`}>
                            {props.title}
                        </h1>
                    </Link>
                )}
                {/* if a subtitle in props render it */}
                {props.subtitle ? (
                    <>
                        {props.target ? (
                            <a
                                href={props.subtitleTo}
                                target={props.target}
                                rel="noopener noreferrer"
                                className={classes.link}
                            >
                                <h3
                                    className={`${classes.subtitle} ${classes.textColor}`}
                                >
                                    {props.subtitle}
                                </h3>
                            </a>
                        ) : (
                            <Link
                                to={
                                    props.subtitleTo
                                        ? props.subtitle
                                        : props.titleTo
                                }
                                className={classes.link}
                            >
                                <h3
                                    className={`${classes.subtitle} ${classes.textColor}`}
                                >
                                    {props.subtitle}
                                </h3>
                            </Link>
                        )}
                    </>
                ) : null}
                {/* render room */}
                <h6
                    className={`${
                        props.bgColor === '#FFFFFF' ? classes.blue : classes.red
                    } ${classes.room}`}
                >
                    {props.room}
                </h6>
            </Row>
            <Row className="mt-5">
                {/* change font color if black background */}
                <span
                    className={`${
                        props.bgColor === '#FFFFFF'
                            ? classes.lightGray
                            : classes.textColor
                    } ${classes.date}`}
                >
                    {/* function to correctly render dates */}
                    {props.date ? (
                        <>
                            {(() => {
                                // extract years and months from open and close dates
                                const openYear = moment(props.date.open).format(
                                    'YYYY'
                                )
                                const closeYear = moment(
                                    props.date.close
                                ).format('YYYY')

                                const openMonth = moment(
                                    props.date.open
                                ).format('MMMM')
                                const closeMonth = moment(
                                    props.date.close
                                ).format('MMMM')

                                // if the exhibition open and close the same year
                                // add year at the end of line
                                if (openYear === closeYear) {
                                    return (
                                        <>
                                            {/* if card is displaying an artfair */}
                                            {props.titleTo === '/fairs' ? (
                                                <>
                                                    <Moment
                                                        date={props.date.close}
                                                        format="MMMM"
                                                    />{' '}
                                                    <Moment
                                                        date={props.date.close}
                                                        format="YYYY"
                                                    />
                                                </>
                                            ) : (
                                                // card is for an exhibition
                                                <>
                                                    {/* exhibition open and close the same month */}
                                                    {openMonth ===
                                                    closeMonth ? (
                                                        <>
                                                            <Moment
                                                                date={
                                                                    props.date
                                                                        .open
                                                                }
                                                                format="MMMM DD"
                                                            />{' '}
                                                            -{' '}
                                                            <Moment
                                                                date={
                                                                    props.date
                                                                        .close
                                                                }
                                                                format="DD"
                                                            />{' '}
                                                            <Moment
                                                                date={
                                                                    props.date
                                                                        .close
                                                                }
                                                                format="YYYY"
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            {/* exhibition open and close at different month */}
                                                            <Moment
                                                                date={
                                                                    props.date
                                                                        .open
                                                                }
                                                                format="MMMM DD"
                                                            />{' '}
                                                            -{' '}
                                                            <Moment
                                                                date={
                                                                    props.date
                                                                        .close
                                                                }
                                                                format="MMMM DD"
                                                            />{' '}
                                                            <Moment
                                                                date={
                                                                    props.date
                                                                        .close
                                                                }
                                                                format="YYYY"
                                                            />
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )
                                } else {
                                    // if close at different years add each month it's corresponding year
                                    return (
                                        <>
                                            <Moment
                                                date={props.date.open}
                                                format="MMMM DD YYYY"
                                            />{' '}
                                            -{' '}
                                            <Moment
                                                date={props.date.close}
                                                format="MMMM DD YYYY"
                                            />
                                        </>
                                    )
                                }
                            })()}
                        </>
                    ) : null}
                    {props.promoDate ? (
                        <>
                            {(() => {
                                return (
                                    <>
                                        <Moment
                                            date={props.promoDate.open}
                                            format="MMMM DD"
                                        />{' '}
                                        -{' '}
                                        <Moment
                                            date={props.promoDate.close}
                                            format="MMMM DD YYYY"
                                        />
                                    </>
                                )
                            })()}
                        </>
                    ) : null}
                </span>
                {/* render texts for breakingNew */}
                <span
                    className={`${
                        props.bgColor === '#FFFFFF'
                            ? classes.darkGray
                            : classes.textColor
                    }  ${classes.date}`}
                >
                    {props.text1}
                </span>
                <span
                    className={`${
                        props.bgColor === '#FFFFFF'
                            ? classes.darkGray
                            : classes.textColor
                    }  ${classes.artists}`}
                >
                    {props.text2}
                </span>
                {/* render artists  */}
                {/* change font color if black background  */}
                <span
                    className={`${
                        props.bgColor === '#FFFFFF'
                            ? classes.darkGray
                            : classes.textColor
                    } ${classes.artists}`}
                >
                    {(() => {
                        // create a new array with all artists
                        let allArtists = []

                        // concat only if artists array exist in props
                        if (props.artists.sgr) {
                            allArtists = allArtists.concat(props.artists.sgr)
                        }
                        if (props.artists.guests) {
                            allArtists = allArtists.concat(props.artists.guests)
                        }

                        if (allArtists.length > 0) {
                            return (
                                <>
                                    {allArtists.map((item, index) => {
                                        // create Link for sgr artists and artfairs
                                        if (
                                            item.__typename === 'Artist' ||
                                            item.__typename === 'ArtFair'
                                        ) {
                                            return (
                                                <React.Fragment
                                                    key={`sgr-${item.__typename}-${item.id}`}
                                                >
                                                    <Link
                                                        to={`${
                                                            item.__typename ===
                                                            'Artist'
                                                                ? '/artists/'
                                                                : '/fairs/'
                                                        }${item.slug}`}
                                                        className={`${
                                                            classes.link
                                                        } ${classes.artists} ${
                                                            props.bgColor ===
                                                            '#FFFFFF'
                                                                ? classes.darkGray
                                                                : classes.textColor
                                                        }`}
                                                    >
                                                        {item.name}
                                                    </Link>
                                                    {/* insert , if need it */}
                                                    <span>
                                                        {index <
                                                        allArtists.length - 1
                                                            ? ', '
                                                            : null}
                                                    </span>
                                                </React.Fragment>
                                            )
                                        } else {
                                            // is a guest artist render it with no link
                                            return (
                                                <React.Fragment
                                                    key={`sgr-${item.__typename}-${item.id}`}
                                                >
                                                    <span>{item.name}</span>
                                                    <span>
                                                        {index <
                                                        allArtists.length - 1
                                                            ? ', '
                                                            : null}
                                                    </span>
                                                </React.Fragment>
                                            )
                                        }
                                    })}
                                </>
                            )
                        }
                    })()}
                </span>
            </Row>
        </Col>
    )
}

CardInfo.propTypes = {
    title: PropTypes.string,
    titleTo: PropTypes.string,
    subtitle: PropTypes.string,
    subtitleTo: PropTypes.string,
    target: PropTypes.string,
    room: PropTypes.string,
    date: PropTypes.object,
    promoDate: PropTypes.object,
    artists: PropTypes.object,
    textAlign: PropTypes.string,
    bgColor: PropTypes.string,
    txtColor: PropTypes.string,
    text1: PropTypes.string,
    text2: PropTypes.string,
}

CardInfo.defaultProps = {
    title: 'Title',
    titleTo: '/',
    artists: 'artists one',
    textAlign: 'left',
    bgColor: '#FFFFFF',
    txtColor: '#1A1A1A',
    target: null,
}

export default CardInfo
