import React from 'react'
import PropTypes from 'prop-types'
import { Col, Stack } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'

import BuildRoom from '../room'
import OpenClose from './openClose'

const useStyles = createUseStyles((theme) => ({
    regular: {
        fontFamily: theme.fontRegular,
        fontSize: '1.1rem',
    },
    textColor: (props) => ({
        color: `${props.txtColor}`,
    }),
    align: (props) => ({
        textAlign: `${props.align ? props.align : 'left'}`,
    }),
    main: {
        color: theme.blue,
    },
    projects: {
        color: theme.red,
    },
}))

const DatePlace = ({ data, styles, xs, sm, md }) => {
    const classes = useStyles(styles)

    return (
        <Col
            xs={xs}
            sm={sm}
            md={md}
            className={`${classes.align} d-flex align-self-end`}
        >
            <Stack className={`${classes.regular}`}>
                <div className="">
                    <BuildRoom
                        room={data.room}
                        className={`${
                            data.room === 'Exposiciones'
                                ? classes.main
                                : classes.projects
                        }`}
                    />
                </div>
                <div className={classes.textColor}>
                    <OpenClose open={data.open} close={data.close} />
                </div>
            </Stack>
        </Col>
    )
}

DatePlace.propTypes = {
    data: PropTypes.object,
    styles: PropTypes.object,
    xs: PropTypes.object,
    sm: PropTypes.object,
    md: PropTypes.object,
}

DatePlace.defaultProps = {
    xs: {},
    sm: {},
    md: {},
}

export default DatePlace
