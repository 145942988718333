import gql from 'graphql-tag'

const ABOUT = gql`
    query About($locale: String!) {
        about(locale: $locale) {
            info
            cover {
                url
            }
        }
        crew: crewMembers(locale: $locale, sort: "order:ASC") {
            id
            name
            bio
            email
            role
            picture {
                id
                formats
            }
        }
        locations {
            id
            address
            city
            country
            phone
            email
            Download {
                id
                title
                file {
                    id
                    url
                }
            }
        }
        socialMedia {
            instagram
            facebook
            whatsapp
        }
        partners(sort: "order:DESC") {
            id
            url
            logo {
                id
                formats
            }
        }
    }
`

export default ABOUT
