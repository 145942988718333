import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'

import Box from '../Box'
import Text from '../text'
import CardImage from '../cardPicture'
import buildMediaUrl from '../../utils/buildMediaUrl'
import Title from '../Texts/title'
import Hr from '../hr'
import CenterColumn from '../Box/centerColumn'
import SocialNetwork from '../social'
import { Context } from '../Store'
import ShimmerImage from '../Image'

const useStyles = createUseStyles((theme) => ({
    address: {
        fontFamily: theme.fontRegular,
        color: '#5E5E5E',
        fontSize: '1.1rem',
    },
    coutry: {
        fontSize: '1rem',
    },
    contact: {
        listStyle: 'none',
        color: '#292929',
        paddingLeft: '0',
        fontFamily: theme.fontRegular,
    },
    crewName: {
        fontFamily: theme.fontMedium,
        color: '#4F4F4F',
    },
    crewRole: {
        fontFamily: theme.fontMedium,
        color: '#868686',
    },
    crewMail: {
        fontFamily: theme.fontRegular,
        color: '#292929',
    },
    h4: {
        fontFamily: theme.fontItalic,
        fontSize: '1rem',
    },
    list: {
        listStyle: 'none',
        padding: 0,
        margin: 0,
        '& a': {
            color: '#4F4F4F',
            fontFamily: theme.fontRegular,
            textDecoration: 'none',
            fontSize: '1rem',
        },
    },
}))

const DisplayAbout = ({ data }) => {
    const [state] = useContext(Context)
    const classes = useStyles()

    return (
        <Container fluid>
            <Row>
                {data.about ? (
                    <CardImage
                        src={buildMediaUrl(data.about.cover.url)}
                        span={12}
                        height={`300px`}
                    />
                ) : null}
            </Row>
            <Box>
                <CenterColumn>
                    <Title text="SGR Galería" />
                    <Hr />
                    {data.locations.map((item) => {
                        return (
                            <Row
                                key={`location-${item.id}`}
                                className={`${classes.address} text-center mb-5`}
                            >
                                <Col xs={12}>{item.address}</Col>
                                <Col xs={12} className={classes.coutry}>
                                    {item.city}, {item.country}
                                </Col>
                            </Row>
                        )
                    })}
                    {data.about ? <Text data={data.about.info} /> : null}

                    <Row className="mt-5 align-items-center">
                        <Col>
                            <ul className={classes.contact}>
                                {data.locations.map((item) => {
                                    return (
                                        <React.Fragment
                                            key={`contact-${item.id}`}
                                        >
                                            <li>{item.phone}</li>
                                            <li>{item.email}</li>
                                        </React.Fragment>
                                    )
                                })}
                            </ul>
                        </Col>
                        <Col>
                            <h4 className={classes.h4}>Descargas</h4>
                            <ul className={classes.list}>
                                {data.locations[0].Download.map((item) => {
                                    return (
                                        <li key={`download-${item.id}`}>
                                            <a
                                                href={buildMediaUrl(
                                                    item.file.url
                                                )}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {item.title}
                                            </a>
                                        </li>
                                    )
                                })}
                            </ul>
                        </Col>
                        <Col className="text-end">
                            <SocialNetwork
                                url={data.socialMedia.instagram}
                                network="instagram"
                                className="me-3"
                            />
                            <SocialNetwork
                                url={data.socialMedia.facebook}
                                network="facebook"
                                className="me-3"
                            />
                            {data.socialMedia.whatsapp ? (
                                <SocialNetwork
                                    url={`https://wa.me/${data.socialMedia.whatsapp}`}
                                    network="whatsapp"
                                    className="me-5"
                                />
                            ) : null}
                        </Col>
                    </Row>
                    <hr />
                    <Row className="mt-5">
                        {data.crew.map((item) => {
                            return (
                                <Col
                                    key={`crew-${item.id}`}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                    lg={4}
                                    className="mb-4 text-center"
                                >
                                    {item.picture ? (
                                        <ShimmerImage
                                            src={item.picture.formats.thumb.url}
                                            size={{
                                                height: item.picture.formats
                                                    .thumb.height,
                                                width: item.picture.formats
                                                    .thumb.width,
                                            }}
                                            fluid
                                        />
                                    ) : null}

                                    <div className={`${classes.crewName} mt-3`}>
                                        {item.name}
                                    </div>
                                    <div className={`${classes.crewRole}`}>
                                        {item.role}
                                    </div>
                                    {item.bio ? (
                                        <>
                                            <div className="mt-4">
                                                <Text data={item.bio} />
                                            </div>
                                            <Hr />
                                        </>
                                    ) : null}

                                    <div className={`${classes.crewMail}`}>
                                        {item.email}
                                    </div>
                                </Col>
                            )
                        })}
                    </Row>
                    <hr />
                    <Row className="mt-5">
                        {data.partners.length ? (
                            <>
                                <h5 className={`${classes.medium} mb-5`}>
                                    {state.language === 'es-CO'
                                        ? 'ALIADOS'
                                        : 'PARTNERS'}
                                </h5>
                                {data.partners.map((item) => {
                                    return (
                                        <Col
                                            key={`partner-${item.id}`}
                                            sm={4}
                                            md={3}
                                            className="mb-5"
                                        >
                                            <a
                                                href={item.url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <ShimmerImage
                                                    src={
                                                        item.logo.formats.small
                                                            .url
                                                    }
                                                    size={{
                                                        height: item.logo
                                                            .formats.small
                                                            .height,
                                                        width: item.logo.formats
                                                            .small.width,
                                                    }}
                                                />
                                            </a>
                                        </Col>
                                    )
                                })}
                            </>
                        ) : null}
                    </Row>
                </CenterColumn>
            </Box>
        </Container>
    )
}

DisplayAbout.propTypes = {
    data: PropTypes.object,
}

export default DisplayAbout
