import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Context } from './Store'
import { createUseStyles } from 'react-jss'
import { useHistory } from 'react-router'

const useStyles = createUseStyles((theme) => ({
    back: {
        color: theme.colorPrimary,
        fontFamily: theme.fontRegular,
        textDecoration: 'none',
        '&:hover': {
            color: theme.textGray,
        },
    },
}))

const GoBack = ({ className }) => {
    const [state] = useContext(Context)
    const classes = useStyles()
    const history = useHistory()

    return (
        <a
            href="#"
            onClick={() => {
                history.goBack()
            }}
            className={`${classes.back} ${className}`}
        >
            {state.language === 'es-CO' ? 'regresar' : 'back'}
        </a>
    )
}

GoBack.propTypes = {
    className: PropTypes.string,
}

GoBack.defaultProps = {
    className: '',
}

export default GoBack
