import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import { Context } from '../Store'

const Seo = ({ title, description }) => {
    const state = useContext(Context)
    return (
        <Helmet
            title={title}
            htmlAttributes={{ lang: state.language }}
            meta={[{ description: description }]}
        />
    )
}

Seo.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    meta: PropTypes.array,
}

Seo.defaultProps = {
    description: 'SGR Galería, contemporary art from Latin America',
}

export default Seo
