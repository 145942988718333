import React, { useState, useEffect } from 'react'
import { Row } from 'react-bootstrap'
import ReactPlaceholder from 'react-placeholder/lib'
import ReactGA from 'react-ga4'

import Query from '../../components/Query'
import LATESTS_FAIRS from '../../queries/fairs/latest'
import Error from '../../components/error'
import { Context } from '../../components/Store'
import ListFairs from './list'
import Box from '../../components/Box'
import CenterColumn from '../../components/Box/centerColumn'
import Seo from '../../components/Seo'

const Fairs = () => {
    const [state] = useState(Context)

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `/fairs` })
    })

    return (
        <>
            <Query query={LATESTS_FAIRS} slug={null} locale={state.language}>
                {({ loading, data, err }) => {
                    if (loading) {
                        return (
                            <Box>
                                <CenterColumn>
                                    <Row>
                                        {[...Array(8)].map((item, index) => {
                                            return (
                                                <Row key={`loading-${index}`}>
                                                    <ReactPlaceholder
                                                        className="py-5 px-5"
                                                        showLoadingAnimation={
                                                            true
                                                        }
                                                        type="rect"
                                                        rows={1}
                                                        ready={!loading}
                                                    />
                                                </Row>
                                            )
                                        })}
                                    </Row>
                                </CenterColumn>
                            </Box>
                        )
                    }
                    if (data) {
                        return (
                            <>
                                <Seo
                                    title={`SGR Galería - ${
                                        state.language === 'es-CO'
                                            ? 'Ferias'
                                            : 'Fairs'
                                    }`}
                                />
                                <ListFairs data={data} />
                            </>
                        )
                    }
                    if (err) {
                        return <Error err={err} />
                    }
                }}
            </Query>
        </>
    )
}

export default Fairs
