import React, { useContext, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import ReactPlaceholder from 'react-placeholder/lib'
import ReactGA from 'react-ga4'

import { Context } from '../../components/Store'
import Query from '../../components/Query'
import HOME from '../../queries/home'
import CardInfo from '../../components/cardInfo'
import CardPicture from '../../components/cardPicture'
import Error from '../../components/error'
import buildMediaUrl from '../../utils/buildMediaUrl'
import Seo from '../../components/Seo'

const Home = () => {
    const [state] = useContext(Context)

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: '/' })
    })

    return (
        <Query
            query={HOME(new Date().toISOString())}
            slug={null}
            locale={state.language}
        >
            {({ loading, data, err }) => {
                if (loading) {
                    return (
                        <Container fluid>
                            <Row className="mb-2">
                                <Col md={4}>
                                    <ReactPlaceholder
                                        className="py-5 px-5"
                                        showLoadingAnimation={true}
                                        type="text"
                                        rows={5}
                                        ready={!loading}
                                    />
                                </Col>
                                <Col md={8}>
                                    <ReactPlaceholder
                                        className="py-5 px-5"
                                        showLoadingAnimation={true}
                                        type="rect"
                                        rows={5}
                                        ready={!loading}
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col md={8}>
                                    <ReactPlaceholder
                                        className="py-5 px-5"
                                        showLoadingAnimation={true}
                                        type="rect"
                                        rows={5}
                                        ready={!loading}
                                    />
                                </Col>
                                <Col md={4}>
                                    <ReactPlaceholder
                                        className="py-5 px-5"
                                        showLoadingAnimation={true}
                                        type="text"
                                        rows={5}
                                        ready={!loading}
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col md={4}>
                                    <ReactPlaceholder
                                        className="py-5 px-5"
                                        showLoadingAnimation={true}
                                        type="text"
                                        rows={5}
                                        ready={!loading}
                                    />
                                </Col>
                                <Col md={8}>
                                    <ReactPlaceholder
                                        className="py-5 px-5"
                                        showLoadingAnimation={true}
                                        type="rect"
                                        rows={5}
                                        ready={!loading}
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col md={8}>
                                    <ReactPlaceholder
                                        className="py-5 px-5"
                                        showLoadingAnimation={true}
                                        type="rect"
                                        rows={5}
                                        ready={!loading}
                                    />
                                </Col>
                                <Col md={4}>
                                    <ReactPlaceholder
                                        className="py-5 px-5"
                                        showLoadingAnimation={true}
                                        type="text"
                                        rows={5}
                                        ready={!loading}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    )
                }
                if (data) {
                    return (
                        <Container fluid>
                            <Seo title={`SGR Galería `} />
                            {data.breakingNew ? (
                                <Row>
                                    <CardPicture
                                        src={buildMediaUrl(
                                            data.breakingNew.cover.url
                                        )}
                                        className="d-none d-md-block"
                                    />
                                    <CardInfo
                                        room={data.breakingNew.tagline}
                                        textAlign="right"
                                        bgColor="#000000"
                                        txtColor="#FFFFFF"
                                        title={data.breakingNew.title}
                                        target="_blank"
                                        titleTo={data.breakingNew.link}
                                        subtitle={data.breakingNew.subtitle}
                                        text1={data.breakingNew.text_1}
                                        text2={data.breakingNew.text_2}
                                    />
                                    <CardPicture
                                        src={buildMediaUrl(
                                            data.breakingNew.cover.url
                                        )}
                                        className="d-block d-md-none"
                                    />
                                </Row>
                            ) : null}
                            {data.currentMain.map((item) => {
                                return (
                                    <Row key={`home-main-${item.id}`}>
                                        <CardInfo
                                            room={
                                                state.language === 'es-CO'
                                                    ? 'Sala de exposiciones'
                                                    : 'Exhibition room'
                                            }
                                            titleTo={`/exhibitions/${item.slug}`}
                                            title={item.title}
                                            subtitle={item.subtitle}
                                            date={{
                                                open: item.open,
                                                close: item.close,
                                            }}
                                            artists={{
                                                sgr: item.sgr_artists,
                                                guests: item.guest_artists,
                                            }}
                                        />
                                        <CardPicture
                                            src={buildMediaUrl(item.cover.url)}
                                        />
                                    </Row>
                                )
                            })}
                            {data.currentProjects.map((item) => {
                                return (
                                    <Row key={`home-projects-${item.id}`}>
                                        <CardPicture
                                            src={buildMediaUrl(item.cover.url)}
                                            className="d-none d-md-block"
                                        />
                                        <CardInfo
                                            room={
                                                state.language === 'es-CO'
                                                    ? 'Sala de proyectos'
                                                    : 'Projects room'
                                            }
                                            textAlign="right"
                                            bgColor="#000000"
                                            txtColor="#FFFFFF"
                                            title={item.title}
                                            titleTo={`/exhibitions/${item.slug}`}
                                            subtitle={item.subtitle}
                                            date={{
                                                open: item.open,
                                                close: item.close,
                                            }}
                                            artists={{
                                                sgr: item.sgr_artists,
                                                guests: item.guest_artists,
                                            }}
                                        />
                                        <CardPicture
                                            src={buildMediaUrl(item.cover.url)}
                                            className="d-block d-md-none"
                                        />
                                    </Row>
                                )
                            })}
                            {data.currentFair.map((item) => {
                                return (
                                    <Row key={`home-fairs-${item.id}`}>
                                        <CardInfo
                                            title={
                                                state.language === 'es-CO'
                                                    ? 'Ferias'
                                                    : 'Fairs'
                                            }
                                            titleTo="/fairs"
                                            subtitle=" "
                                            date={{
                                                open: item.open,
                                                close: item.close,
                                            }}
                                            artists={{
                                                sgr: {
                                                    __typename: item.__typename,
                                                    id: item.id,
                                                    slug: item.slug,
                                                    name: item.fair,
                                                },
                                            }}
                                        />
                                        <CardPicture
                                            src={buildMediaUrl(item.cover.url)}
                                        />
                                    </Row>
                                )
                            })}
                            {data.focus.length > 0 ? (
                                <>
                                    {data.focus.map((item) => {
                                        return (
                                            <Row key={`home-promo-${item.id}`}>
                                                <CardPicture
                                                    src={buildMediaUrl(
                                                        item.cover.url
                                                    )}
                                                />
                                                <CardInfo
                                                    textAlign="right"
                                                    bgColor="#000000"
                                                    txtColor="#FFFFFF"
                                                    title={item.title}
                                                    subtitle={item.subtitle}
                                                    titleTo={`/exhibitions/${item.slug}`}
                                                    room="enfoqueSGR"
                                                    promoDate={{
                                                        open: item.open,
                                                        close: item.close,
                                                    }}
                                                    artists={{
                                                        sgr: item.sgr_artists,
                                                        guests: item.guest_artists,
                                                    }}
                                                />
                                            </Row>
                                        )
                                    })}
                                </>
                            ) : null}
                            {/* {(() => {
                                let random = Math.floor(
                                    Math.random() * data.artshops.length
                                )
                                return (
                                    <Row>
                                        {console.log(data.artshops[random])}
                                        <CardPicture
                                            src={buildMediaUrl(
                                                data.artshops[random].image.url
                                            )}
                                            className="d-none d-md-block"
                                        />
                                        <CardInfo
                                            textAlign="right"
                                            bgColor="#000000"
                                            txtColor="#FFFFFF"
                                            title="Artshop"
                                            titleTo={
                                                'https://artshop.sgr-art.com'
                                            }
                                            subtitle={
                                                data.artshops[random].title
                                            }
                                            subtitleTo={
                                                data.artshops[random].url
                                            }
                                            target="_blank"
                                            artists={{
                                                sgr: data.artshops[random]
                                                    .artista,
                                                guests: data.artshops[random]
                                                    .artista_invitado,
                                            }}
                                        />
                                        <CardPicture
                                            src={buildMediaUrl(
                                                data.artshops[random].image.url
                                            )}
                                            className="d-block d-md-none"
                                        />
                                    </Row>
                                )
                            })()} */}
                            {/*<Row>
                                
                            </Row> */}
                        </Container>
                    )
                }
                if (err) {
                    return <Error err={err} />
                }
            }}
        </Query>
    )
}

export default Home
