import gql from 'graphql-tag'

const MENU_FOOTER = () => {
    const query = gql`
        query Locations {
            locations {
                id
                address
                city
                country
                phone
                email
            }
        }
    `
    return query
}

export default MENU_FOOTER
