const Reducer = (state, action) => {
    switch (action.type) {
        case 'SET_MENU_ITEMS':
            return {
                ...state,
                menu: action.payload,
            }

        case 'SET_LANGUAGE':
            return {
                ...state,
                language: action.payload,
            }

        default:
            return state
    }
}

export default Reducer
