import gql from 'graphql-tag'

const POST = gql`
    query Post($slug: String!, $locale: String!) {
        post: blogPosts(locale: $locale, where: { slug: $slug }) {
            id
            title
            subtitle
            author
            image_footnote
            content
            date
            cover {
                id
                url
                formats
            }
        }
    }
`

export default POST
