import gql from 'graphql-tag'

const ARTISTS = gql`
    fragment Artista on Artist {
        id
        slug
        name
        birthyear
        deathyear
        birthcity
        birthcountry
        picture {
            id
            url
            formats
        }
    }
    query Artist {
        represented: artists(
            where: { type: "representado" }
            sort: "name:ASC"
        ) {
            ...Artista
        }
        collab: artists(where: { type: "colaboracion" }, sort: "name:ASC") {
            ...Artista
        }
    }
`

export default ARTISTS
