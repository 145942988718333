import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'

import buildMediaUrl from '../../utils/buildMediaUrl'
import Box from '../Box'
import CenterColumn from '../Box/centerColumn'
import Title from '../Texts/title'
import SubTitle from '../Texts/subtitle'
import Hr from '../hr'
import BuildRoom from '../room'
import Text from '../text'
import CardImage from '../cardPicture'
import ArtistsList from '../Exhibition/artists'
import OpenClose from '../Exhibition/openClose'
import Gridbox from '../gridBox'
import { Context } from '../Store'
import ShimmerImage from '../Image'
import { SRLWrapper } from 'simple-react-lightbox'
import lightBoxConfig from '../../utils/lightBoxConfig'

const useStyles = createUseStyles((theme) => ({
    openClose: {
        fontFamily: theme.fontRegular,
        fontSize: '1.2rem',
        color: '#606060',
    },
    bold: {
        fontFamily: theme.fontBold,
        fontSize: '1.1rem',
    },
    regular: {
        fontFamily: theme.fontRegular,
        fontSize: '1.1rem',
    },
    medium: {
        fontFamily: theme.fontMedium,
    },
    lightGray: {
        color: '#606060',
    },
    darkGray: {
        color: '#4F4F4F',
    },
    download: {
        '& a': {
            color: '#4F4F4F',
            textDecoration: 'none',
            '&:hover': {
                color: '#606060',
            },
        },
    },
    press: {
        '& ul': {
            padding: 0,
            '& li': {
                listStyle: 'none',
                '& a': {
                    fontFamily: theme.fontRegular,
                    textDecoration: 'none',
                    color: '#606060',
                    '&:hover': {
                        color: '#4F4F4F',
                    },
                },
            },
        },
    },
}))

const DisplayExhibition = ({ data }) => {
    const [state] = useContext(Context)
    const classes = useStyles()

    return (
        <Container fluid>
            <Row>
                <CardImage
                    src={buildMediaUrl(data.cover.url)}
                    span={12}
                    height={`300px`}
                />
            </Row>
            <Box>
                <CenterColumn>
                    <Title text={data.title} />
                    {data.subtitle ? <SubTitle text={data.subtitle} /> : null}
                    <Row className="text-center mt-3">
                        <Col>
                            {data.sgr_artists.length ||
                            data.guest_artists.length ? (
                                <ArtistsList
                                    sgr={data.sgr_artists}
                                    guests={data.guest_artists}
                                    room={data.room}
                                    styles={{
                                        font: 'DINNextLTPro-Light',
                                        fontSize: '1.5rem',
                                        color: '#292929',
                                    }}
                                />
                            ) : null}
                        </Col>
                    </Row>
                    <Hr />
                    <Row className="text-center mt-3 mb-2">
                        <BuildRoom
                            room={data.room}
                            styles={{
                                color: `${
                                    data.room === 'Exposiciones'
                                        ? '#7399CA'
                                        : '#FF635F'
                                }`,
                                font: 'DINNextLTPro-Regular',
                                fontSize: '1.3rem',
                            }}
                        />
                    </Row>
                    <Row className="text-center mb-5">
                        <Col className={classes.openClose}>
                            <OpenClose open={data.open} close={data.close} />
                        </Col>
                    </Row>
                    <Text data={data.info} />
                    <Row className="text-center">
                        {data.curator ? (
                            <Col>
                                <span
                                    className={`${classes.bold} ${classes.lightGray} me-2`}
                                >
                                    {state.language === 'es-CO'
                                        ? 'Curaduría: '
                                        : 'Curator: '}
                                </span>
                                <span
                                    className={`${classes.regular} ${classes.lightGray}`}
                                >
                                    {data.curator}
                                </span>
                            </Col>
                        ) : null}
                    </Row>
                    <Row className="mt-2 mb-3">
                        <Col className={classes.download}>
                            {data.PDF ? (
                                <a
                                    href={buildMediaUrl(data.PDF.url)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={`${classes.bold} me-3`}
                                >
                                    [ PDF ]
                                </a>
                            ) : null}

                            {data.press_kit ? (
                                <a
                                    href={buildMediaUrl(data.press_kit.url)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={`${classes.medium}`}
                                >
                                    PRESSKIT
                                </a>
                            ) : null}
                        </Col>
                    </Row>
                    <Row className="my-4">
                        <Col className={classes.press}>
                            {data.Press.length ? (
                                <>
                                    <h5 className={classes.medium}>
                                        {state.language === 'es-CO'
                                            ? 'Prensa'
                                            : 'Press'}
                                    </h5>
                                    <ul>
                                        {data.Press.map((item) => {
                                            return (
                                                <li key={`press-${item.id}`}>
                                                    <a
                                                        href={item.url}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {item.title}
                                                    </a>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </>
                            ) : null}
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col>
                            {data.pictures.length ? (
                                <SRLWrapper options={lightBoxConfig}>
                                    {data.pictures.map((item) => {
                                        return (
                                            <ShimmerImage
                                                key={`picture-${item.id}`}
                                                src={item.formats.large.url}
                                                className="mb-3"
                                                fluid
                                            />
                                        )
                                    })}
                                </SRLWrapper>
                            ) : null}
                        </Col>
                    </Row>
                    <Row className="my-5">
                        {data.artwork_series.length ? (
                            <>
                                {data.artwork_series.map((item) => {
                                    console.log(item)
                                    return (
                                        <Gridbox
                                            key={`artwork-series-${item.id}`}
                                            data={{
                                                type: 'artwork',
                                                content: item,
                                            }}
                                            to={`/artwork/${item.artistas[0].slug}/${item.artistas[0].id}/${item.year}`}
                                            span={4}
                                            className="mb-4"
                                        />
                                    )
                                })}
                            </>
                        ) : null}
                    </Row>
                </CenterColumn>
            </Box>
        </Container>
    )
}

DisplayExhibition.propTypes = {
    data: PropTypes.object,
}

export default DisplayExhibition
