import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment/min/moment-with-locales'
import Moment from 'react-moment'

import { Context } from '../Store'

const OpenClose = ({ open, close }) => {
    const [state] = useContext(Context)
    // Sets the moment instance to use.
    Moment.globalMoment = moment
    // Set the locale for every react-moment instance to French.
    Moment.globalLocale = state.language

    // extract years from open and close dates
    const _open = moment(open).format('YYYY')
    const _close = moment(close).format('YYYY')
    const _openMonth = moment(open).format('MMMM')
    const _closeMonth = moment(close).format('MMMM')

    return (
        <>
            {_open === _close ? (
                // if the exhibition open and close the same year
                // add year at the end of line
                <>
                    {/* if the exhibition open and close at the same month */}
                    {_openMonth === _closeMonth ? (
                        <>
                            <Moment date={open} format="MMMM DD" /> -{' '}
                            <Moment date={close} format="DD" />{' '}
                            <Moment date={close} format="YYYY" />
                        </>
                    ) : (
                        <>
                            {/* exhibition open and close at different month */}
                            <Moment date={open} format="MMMM DD" /> -{' '}
                            <Moment date={close} format="MMMM DD" />{' '}
                            <Moment date={close} format="YYYY" />
                        </>
                    )}
                </>
            ) : (
                //if close at different years add each month it's corresponding year
                <>
                    <Moment date={open} format="MMMM DD YYYY" /> -{' '}
                    <Moment date={close} format="MMMM DD YYYY" />
                </>
            )}
        </>
    )
}

OpenClose.propTypes = {
    open: PropTypes.string,
    close: PropTypes.string,
}

export default OpenClose
