import React from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles(() => ({
    hr: {
        margin: '2% 48% 3%',
        borderTop: '0.1rem solid #000000',
    },
}))

const Hr = () => {
    const classes = useStyles()

    return <hr className={classes.hr} />
}

export default Hr
