import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
    list: (props) => ({
        overflowX: 'scroll',
        listStyle: 'none',
        marginTop: '1.5rem',
        '& li': {
            display: 'inline',
            marginRight: '1rem',
            '& a': {
                textDecoration: 'none',
                color: `${props.color ? props.color : theme.colorPrimary}`,
                fontFamily: theme.fontBold,
                fontSize: '1.5rem',
                '&:hover': {
                    color: theme.textGray,
                },
            },
            '& span': {
                textDecoration: 'none',
                color: theme.colorPrimary,
                fontFamily: theme.fontRegular,
                fontSize: '1.5rem',
            },
        },
    }),
}))

const Years = (props) => {
    const classes = useStyles(props)

    // convert api response to moment years
    const years = props.list.map((item) => {
        return moment(item.open).format('YYYY')
    })
    // remove dupes
    const unique = [...new Set(years)]

    return (
        <ul className={classes.list}>
            {unique.map((item) => {
                return (
                    <li key={`toyear-${item}`}>
                        {item === props.current ? (
                            <span>{item}</span>
                        ) : (
                            <Link to={`/${props.type}/year/${item}`}>
                                {item}
                            </Link>
                        )}
                    </li>
                )
            })}
        </ul>
    )
}

Years.propTypes = {
    list: PropTypes.array,
    type: PropTypes.string,
    current: PropTypes.string,
    color: PropTypes.string,
}

Years.defaultProps = {
    current: '',
    color: null,
}

export default Years
