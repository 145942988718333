import gql from 'graphql-tag'

const BLOG = (limit, start) => {
    const query = gql`
    query Blog($locale: String!) {
        totalPosts: blogPostsCount
        posts: blogPosts(locale: $locale, sort: "Date:DESC", limit: ${limit}, start: ${start}) {
            id
            slug
            title
            subtitle
            text_1
            text_2
            author
            cover {
                id
                url
            }
        }
    }
`
    return query
}

export default BLOG
