const buildMediaUrl = (uri) => {
    if (process.env.NODE_ENV === 'development') {
        return `${process.env.REACT_APP_BACKEND_URL_DEV}${uri}`
    } else {
        return `${process.env.REACT_APP_BACKEND_URL_PRO}${uri}`
    }
}

const injectMediaUrl = (data) => {
    data.blocks.map((item) => {
        if (item.type === 'image') {
            const url = `${buildMediaUrl(item.data.file.url)}`
            item.data.file.url = url
        }
    })
    return data
}

export default injectMediaUrl
