import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment/min/moment-with-locales'
import Moment from 'react-moment'
import { Link } from 'react-router-dom'
import { Container, Col, Row } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'

import Box from '../../components/Box'
import CenterColumn from '../../components/Box/centerColumn'
import Years from '../../components/Years'
import CardImage from '../../components/cardPicture'
import buildMediaUrl from '../../utils/buildMediaUrl'
import { Context } from '../../components/Store'

const useStyles = createUseStyles((theme) => ({
    fair: {
        backgroundColor: '#000000',
        '& a': {
            textDecoration: 'none',
            color: '#FFFFFF',
            fontSize: '2rem',
            '&:hover': {
                color: '#606060',
            },
        },
    },
    bold: {
        fontFamily: theme.fontBold,
    },
    open: {
        color: '#FFFFFF',
        textAlign: 'right',
        fontFamily: theme.fontLight,
        fontSize: '1.4rem',
    },
    years: {
        backgroundColor: '#000000',
        color: '#FFFFFF !important',
        overflowX: 'scroll',
    },
}))

const ListFairs = ({ data, year }) => {
    const classes = useStyles()
    const [state] = useContext(Context)
    // Sets the moment instance to use.
    Moment.globalMoment = moment
    // Set the locale for every react-moment instance to French.
    Moment.globalLocale = state.language

    return (
        <Box>
            <CenterColumn>
                <Container fluid>
                    <Row className={`${classes.years}`}>
                        <Years
                            list={data.dates}
                            type="fairs"
                            color="#FFFFFF"
                            current={`${year ? year : null}`}
                        />
                    </Row>
                </Container>
                {data.artFairs.map((item) => {
                    return (
                        <Container key={`fair-${item.id}`} fluid>
                            <Row>
                                <CardImage
                                    src={buildMediaUrl(
                                        item.cover.formats.large.url
                                    )}
                                    span={12}
                                    height="300px"
                                />
                            </Row>
                            <Row
                                className={`${classes.fair} py-md-2 px-md-5 align-items-center`}
                            >
                                <Col xs={12} md={12} lg={6}>
                                    <Link
                                        to={`/fairs/${item.slug}`}
                                        className={classes.bold}
                                    >
                                        {item.fair}
                                    </Link>
                                </Col>
                                <Col
                                    xs={12}
                                    md={12}
                                    lg={6}
                                    className={classes.open}
                                >
                                    <Moment
                                        date={item.open}
                                        format="MMMM YYYY"
                                    />
                                </Col>
                            </Row>
                        </Container>
                    )
                })}
            </CenterColumn>
        </Box>
    )
}

ListFairs.propTypes = {
    data: PropTypes.object,
    year: PropTypes.string,
}

ListFairs.defaultProps = {
    year: '',
}

export default ListFairs
