import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'

import Oembed from '../../components/oembed'
import ShimmerImage from '../Image'

const useStyles = createUseStyles((theme) => ({
    title: {
        color: '#4F4F4F',
        fontFamily: theme.fontBold,
        fontSize: '1.3rem',
        marginBottom: 0,
    },
    technique: {
        color: '#606060',
        fontFamily: theme.fontItalic,
        fontSize: '1.1rem',
        marginBottom: 0,
    },
    dimension: {
        color: '#606060',
        fontFamily: theme.fontRegular,
        fontSize: '1.1rem',
        marginBottom: 0,
    },
    year: {
        color: '#606060',
        fontFamily: theme.fontMedium,
        fontSize: '1.1rem',
        marginBottom: 0,
    },
}))

const Artwork = ({ data }) => {
    const classes = useStyles()

    return (
        <Row className="mb-5">
            <Col>
                {data.images && !data.video ? (
                    <>
                        {data.images.map((img) => {
                            return (
                                <ShimmerImage
                                    key={`artwork-image-${img.id}`}
                                    src={img.formats.large.url}
                                    className="mb-5"
                                    fluid
                                />
                            )
                        })}
                    </>
                ) : null}
                {data.video ? <Oembed data={data.video} /> : null}

                <h1 className={`${classes.title} mt-4`}>{data.title}</h1>

                {data.technique ? (
                    <h2 className={classes.technique}>{data.technique}</h2>
                ) : null}

                {data.dimension ? (
                    <h3 className={classes.dimension}>{data.dimension}</h3>
                ) : null}

                {data.year ? (
                    <h4 className={classes.year}>{data.year}</h4>
                ) : null}
            </Col>
        </Row>
    )
}

Artwork.propTypes = {
    data: PropTypes.object,
}

export default Artwork
