import React from 'react'
import PropTypes from 'prop-types'
import { Container, Row } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'

import CardImage from '../cardPicture'
import buildMediaUrl from '../../utils/buildMediaUrl'
import Title from './title'
import DatePlace from './datePlace'

const useStyles = createUseStyles(() => ({
    col: (props) => ({
        paddingLeft: '2.7rem',
        paddingRight: '2.7rem',
        backgroundColor: props.bgColor,
    }),
}))

const ExhibitionList = ({ data, styles, title }) => {
    const classes = useStyles(styles)

    return (
        <Container fluid>
            <Row>
                <CardImage
                    src={buildMediaUrl(data.cover.url)}
                    span={12}
                    height={'160px'}
                />
            </Row>
            <Row className={`${classes.col} py-5`}>
                {title === 'left' ? (
                    <>
                        <Title data={data} styles={styles} />
                        <DatePlace
                            data={data}
                            md={{ span: 6 }}
                            styles={{
                                align: 'right',
                                txtColor: styles.txtColor,
                            }}
                        />
                    </>
                ) : (
                    <>
                        <DatePlace
                            xs={{ span: 12, order: 'last' }}
                            sm={{ order: 'first' }}
                            md={{ span: 6 }}
                            data={data}
                            styles={styles}
                        />
                        <Title
                            data={data}
                            styles={{
                                txtColor: styles.txtColor,
                                align: 'right',
                            }}
                        />
                    </>
                )}
            </Row>
        </Container>
    )
}

ExhibitionList.propTypes = {
    data: PropTypes.object,
    styles: PropTypes.object,
    title: PropTypes.string,
}

ExhibitionList.defaultProps = {
    title: 'left',
}

export default ExhibitionList
