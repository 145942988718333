import React from 'react'

const NotFound = () => {
    return (
        <>
            <h1>404 - NotFound</h1>
        </>
    )
}

export default NotFound
