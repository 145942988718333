import React from 'react'
import PropTypes from 'prop-types'
import { Container, Row } from 'react-bootstrap'

const Box = ({ children }) => {
    return (
        <Container fluid className="mt-5">
            <Row className="px-0 py-0 px-md-5 py-md-5">{children}</Row>
        </Container>
    )
}

Box.propTypes = {
    children: PropTypes.node,
}

export default Box
