import gql from 'graphql-tag'

const EXHIBITION = gql`
    query Exhibition($slug: String!, $locale: String!) {
        exhibition: exhibitions(locale: $locale, where: { slug: $slug }) {
            id
            slug
            title
            subtitle
            open
            close
            room
            info
            curator
            cover {
                id
                url
            }
            sgr_artists {
                id
                name
                slug
            }
            guest_artists {
                id
                name
            }
            PDF {
                id
                url
            }
            press_kit {
                id
                url
            }
            Press {
                id
                title
                url
            }
            pictures {
                id
                url
                formats
            }
            artwork_series {
                id
                slug
                year
                cover {
                    id
                    url
                    formats
                }
                artistas_invitados {
                    id
                    name
                }
                artistas {
                    id
                    name
                    slug
                }
            }
        }
    }
`

export default EXHIBITION
