import React from 'react'
import ReactPlaceholder from 'react-placeholder'
import 'react-placeholder/lib/reactPlaceholder.css'
import { Container, Row, Col } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'

import Query from '../Query'
import FOOTER_QUERY from '../../queries/footer/footer'
import Error from '../error'

const useStyles = createUseStyles((theme) => ({
    footer: {
        backgroundColor: '#000000',
        color: theme.textGray,
        fontFamily: theme.fontRegular,
        fontSize: '1.2rem',
        textAlign: 'center',
    },
}))

const Footer = () => {
    const classes = useStyles()

    return (
        <Query query={FOOTER_QUERY()} slug={null}>
            {({ loading, data, err }) => {
                if (loading) {
                    return (
                        <Container fluid className={classes.footer}>
                            <Row>
                                <Col className="my-auto py-5">
                                    <ReactPlaceholder
                                        showLoadingAnimation={true}
                                        type="text"
                                        rows={1}
                                        ready={!loading}
                                    />
                                </Col>
                                <Col className="my-auto py-5">
                                    <ReactPlaceholder
                                        showLoadingAnimation={true}
                                        type="text"
                                        rows={1}
                                        ready={!loading}
                                    />
                                </Col>
                                <Col className="my-auto py-5">
                                    <ReactPlaceholder
                                        showLoadingAnimation={true}
                                        type="text"
                                        rows={1}
                                        ready={!loading}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    )
                }
                if (data) {
                    return (
                        <Container fluid className={classes.footer}>
                            <Row>
                                {data.locations.map((item) => {
                                    return (
                                        <React.Fragment key={item.id}>
                                            <Col
                                                xs={12}
                                                sm={12}
                                                md={4}
                                                className="my-auto py-3 py-md-5"
                                            >
                                                <span>{item.phone}</span>
                                            </Col>
                                            <Col
                                                xs={12}
                                                sm={12}
                                                md={4}
                                                className="my-auto py-3 py-md-5"
                                            >
                                                <span>{item.email}</span>
                                            </Col>
                                            <Col
                                                xs={12}
                                                sm={12}
                                                md={4}
                                                className="my-auto py-3 py-md-5"
                                            >
                                                <span>{item.address}</span>{' '}
                                                <br />
                                                <span>{item.city}</span>,
                                                <span>{item.country}</span>
                                            </Col>
                                        </React.Fragment>
                                    )
                                })}
                            </Row>
                        </Container>
                    )
                }
                if (err) {
                    console.log(err)
                    return <Error err={err} />
                }
            }}
        </Query>
    )
}

export default Footer
