import gql from 'graphql-tag'

const EXHIBITION_YEARS = (today) => {
    const query = gql`
        query Years {
            exhibitions(locale: "es-CO", sort: "open:DESC") {
                open
            },
            currentMain: exhibitions(
                limit: 1,
                sort: "open:DESC",
                where: {
                    _or: [
                        {room: "Exposiciones", open_lte: "${today}", close_gte: "${today}" }, 
                        {room: "Exposiciones", close_lte: "${today}"}
                    ]
                }
            ){
                id
                title
                subtitle
                slug
                room
                open
                close
                cover {
                    id
                    url
                }
                sgr_artists (sort: "name:DESC"){
                    id
                    slug
                    name
                }
                guest_artists (sort: "name:DESC"){
                    id
                    name
                }
            },
            currentProjects: exhibitions(
                limit: 1,
                sort: "open:DESC",
                where: {
                    _or: [
                        {room: "Proyectos", open_lte: "${today}", close_gte: "${today}" }, 
                        {room: "Proyectos", close_lte: "${today}"}
                    ]
                }
            ){
                id
                title
                subtitle
                slug
                room
                open
                close
                cover {
                    id
                    url
                }
                sgr_artists (sort: "name:DESC"){
                    id
                    slug
                    name
                }
                guest_artists (sort: "name:DESC"){
                    id
                    name
                }
            }
        }
    `
    return query
}

export default EXHIBITION_YEARS
