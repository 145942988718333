import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Container, Row } from 'react-bootstrap'
import ReactPlaceholder from 'react-placeholder/lib'
import ReactGA from 'react-ga4'

import { Context } from '../../components/Store'
import Query from '../../components/Query'
import EXHIBITION from '../../queries/exhibition/exhibition'
import Error from '../../components/error'
import DisplayExhibition from '../../components/displayExhibition'
import Box from '../../components/Box'
import CenterColumn from '../../components/Box/centerColumn'
import Seo from '../../components/Seo'

const Exhibition = () => {
    const [state] = useContext(Context)
    const { slug } = useParams()

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `/exhibitions/${slug}` })
    })

    return (
        <>
            <Query query={EXHIBITION} slug={slug} locale={state.language}>
                {({ loading, data, err }) => {
                    if (loading) {
                        return (
                            <Container>
                                {[...Array(3)].map((item, index) => {
                                    return (
                                        <Row key={`loading-${index}`}>
                                            <ReactPlaceholder
                                                className="py-5 px-5"
                                                showLoadingAnimation={true}
                                                type="rect"
                                                rows={1}
                                                ready={!loading}
                                            />
                                        </Row>
                                    )
                                })}
                                <Box>
                                    <CenterColumn>
                                        <ReactPlaceholder
                                            className=""
                                            showLoadingAnimation={true}
                                            type="text"
                                            rows={20}
                                            ready={!loading}
                                        />
                                    </CenterColumn>
                                </Box>
                            </Container>
                        )
                    }
                    if (data) {
                        return (
                            <>
                                {data.exhibition.map((item) => {
                                    return (
                                        <>
                                            <Seo
                                                title={`SGR Galería - ${item.title}`}
                                            />
                                            <DisplayExhibition
                                                data={item}
                                                key={`exhibition-${item.id}`}
                                            />
                                        </>
                                    )
                                })}
                            </>
                        )
                    }

                    if (err) {
                        return <Error err={err} />
                    }
                }}
            </Query>
        </>
    )
}

export default Exhibition
