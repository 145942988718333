import React, { useContext, useEffect } from 'react'
import ReactGA from 'react-ga4'

import { Context } from '../../components/Store'
import Query from '../../components/Query'
import EXHIBITION_YEARS from '../../queries/exhibition/years'
import Years from '../../components/Years'
import ExhibitionList from '../../components/Exhibition/list'
import { Container, Row } from 'react-bootstrap'
import ReactPlaceholder from 'react-placeholder/lib'
import Seo from '../../components/Seo'

const Exhibitions = () => {
    const [state] = useContext(Context)

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `/exhibitions` })
    })

    return (
        <>
            <Query
                query={EXHIBITION_YEARS(new Date().toISOString())}
                slug={null}
                locale={state.locale}
            >
                {({ loading, data, err }) => {
                    if (loading) {
                        return (
                            <Container fluid>
                                <Row>
                                    <ReactPlaceholder
                                        className="py-5 px-5 mb-3"
                                        showLoadingAnimation={true}
                                        type="rect"
                                        rows={1}
                                        ready={!loading}
                                    />
                                </Row>
                                {[...Array(6)].map((item, index) => {
                                    return (
                                        <Row
                                            key={`loading-${index}`}
                                            className="mb-3"
                                        >
                                            <ReactPlaceholder
                                                className="py-5 px-5"
                                                showLoadingAnimation={true}
                                                type="rect"
                                                rows={1}
                                                ready={!loading}
                                            />
                                        </Row>
                                    )
                                })}
                            </Container>
                        )
                    }
                    if (data) {
                        return (
                            <>
                                <Seo
                                    title={`SGR Galería - ${
                                        state.language === 'es-CO'
                                            ? 'Exposiciones'
                                            : 'Exhibitions'
                                    }`}
                                />
                                <Years
                                    list={data.exhibitions}
                                    type="exhibitions"
                                />
                                {/* main room */}
                                {data.currentMain.map((item) => {
                                    return (
                                        <ExhibitionList
                                            key={`main-${item.id}`}
                                            data={item}
                                            title="left"
                                            styles={{
                                                textAlign: 'center',
                                                bgColor: '#000000',
                                                txtColor: '#FFFFFF',
                                            }}
                                        />
                                    )
                                })}
                                {data.currentProjects.map((item) => {
                                    return (
                                        <ExhibitionList
                                            key={`main-${item.id}`}
                                            data={item}
                                            title="right"
                                            styles={{
                                                textAlign: 'center',
                                                bgColor: '#FFFFFF',
                                                txtColor: '#000000',
                                            }}
                                        />
                                    )
                                })}
                            </>
                        )
                    }
                    if (err) {
                        return <h1>{err}</h1>
                    }
                }}
            </Query>
        </>
    )
}

export default Exhibitions
