import React from 'react'
import PropTypes from 'prop-types'
import Row from 'react-bootstrap/Row'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
    title: (props) => ({
        textAlign: props.align,
        color: '#4F4F4F',
        '& h1': {
            fontFamily: theme.fontMedium,
            fontSize: '4rem',
        },
    }),
}))

const Title = (props) => {
    const classes = useStyles(props)

    return (
        <Row className={`${classes.title} ${props.className}`}>
            <h1>{props.text}</h1>
        </Row>
    )
}

Title.propTypes = {
    text: PropTypes.string,
    align: PropTypes.string,
    className: PropTypes.string,
}

Title.defaultProps = {
    align: 'center',
}

export default Title
