import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
    pagination: {
        color: theme.colorPrimary,
        textDecoration: 'none',
        '&:hover': {
            color: theme.secondaryColor,
        },
    },
}))

const Pagination = (props) => {
    const classes = useStyles()
    const [totalPages] = useState(Math.ceil(props.totalPosts / props.perPage))

    return (
        <Row>
            <Col>
                {[...Array(totalPages)].map((item, index) => {
                    return (
                        <a
                            key={`page-${index}`}
                            href={`#`}
                            onClick={() =>
                                // eslint-disable-next-line react/prop-types
                                props.setStart(index * props.perPage)
                            }
                            className={`${classes.pagination} me-2`}
                        >
                            {`${index + 1}`}
                        </a>
                    )
                })}
            </Col>
        </Row>
    )
}

Pagination.propTypes = {
    totalPosts: PropTypes.number,
    perPage: PropTypes.number,
}

export default Pagination
