import React, { useContext, useState, useEffect } from 'react'
// import { Link } from 'react-router-dom'
import { Container, Row } from 'react-bootstrap'
// import { createUseStyles } from 'react-jss'
import ReactPlaceholder from 'react-placeholder'
import ReactGA from 'react-ga4'

import { Context } from '../../components/Store'
import Query from '../../components/Query'
import BLOG from '../../queries/blog'
import Error from '../../components/error'
// import Box from '../../components/Box'
import Pagination from './pagination'
// import SubTitle from '../../components/Texts/subtitle'
// import Text from '../../components/text'
import buildMediaUrl from '../../utils/buildMediaUrl'
import CardPicture from '../../components/cardPicture'
import Seo from '../../components/Seo'
import CardInfo from '../../components/cardInfo'

// const useStyles = createUseStyles((theme) => ({
//     title: {
//         textDecoration: 'none',
//     },
//     author: {
//         fontFamily: theme.fontLight,
//         color: '#606060',
//     },
// }))

const Blog = () => {
    const [state] = useContext(Context)
    // const classes = useStyles()
    // pagination
    // number of posts to display
    const [postsPerPage] = useState(20)
    // set the start number for query
    const [start, setStart] = useState(0)

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `/blog` })
    })

    return (
        <Query
            query={BLOG(postsPerPage, start)}
            slug={null}
            locale={state.language}
        >
            {({ loading, data, err }) => {
                if (loading) {
                    return (
                        <Container fluid>
                            <Row>
                                <ReactPlaceholder
                                    className="py-5 px-5 mb-3"
                                    showLoadingAnimation={true}
                                    type="rect"
                                    rows={1}
                                    ready={!loading}
                                />
                            </Row>
                            {[...Array(6)].map((item, index) => {
                                return (
                                    <Row
                                        key={`loading-${index}`}
                                        className="mb-3"
                                    >
                                        <ReactPlaceholder
                                            className="py-5 px-5"
                                            showLoadingAnimation={true}
                                            type="rect"
                                            rows={1}
                                            ready={!loading}
                                        />
                                    </Row>
                                )
                            })}
                        </Container>
                    )
                }

                if (data) {
                    return (
                        <Container fluid>
                            <Seo title={'News'} />
                            {data.posts.map((item, index) => {
                                if (index % 2 === 0) {
                                    return (
                                        <Row key={`post-${item.id}`}>
                                            <CardPicture
                                                src={buildMediaUrl(
                                                    item.cover.url
                                                )}
                                                className="d-none d-md-block"
                                            />
                                            <CardInfo
                                                title={item.title}
                                                room={item.subtitle}
                                                titleTo={`/blog/${item.slug}`}
                                                text1={item.text_1}
                                                text2={item.text_2}
                                            />
                                            <CardPicture
                                                src={buildMediaUrl(
                                                    item.cover.url
                                                )}
                                                className="d-block d-md-none"
                                            />
                                        </Row>
                                    )
                                } else {
                                    return (
                                        <Row key={`post-${item.id}`}>
                                            <CardInfo
                                                title={item.title}
                                                room={item.subtitle}
                                                titleTo={`/blog/${item.slug}`}
                                                text1={item.text_1}
                                                text2={item.text_2}
                                                bgColor="#000000"
                                                txtColor="#FFFFFF"
                                            />
                                            <CardPicture
                                                src={buildMediaUrl(
                                                    item.cover.url
                                                )}
                                            />
                                        </Row>
                                    )
                                }
                            })}
                            <Row className="mt-5 mb-5">
                                <Pagination
                                    totalPosts={data.totalPosts}
                                    perPage={postsPerPage}
                                    setStart={setStart}
                                />
                            </Row>
                        </Container>
                    )
                }

                if (err) {
                    return <Error err={err} />
                }
            }}
        </Query>
    )
}

export default Blog
