import React, { createContext, useReducer } from 'react'
import PropTypes from 'prop-types'
import Reducer from './Reducer'

const initialState = {
    menu: [],
    language: 'es-CO',
}

const Store = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, initialState)
    return (
        <Context.Provider value={[state, dispatch]}>
            {children}
        </Context.Provider>
    )
}

Store.propTypes = {
    children: PropTypes.element.isRequired,
}

export const Context = createContext({ state: initialState })
export default Store
