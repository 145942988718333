import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'

const cache = new InMemoryCache()
const link = new HttpLink({
    // eslint-disable-next-line no-undef
    uri: `${
        process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_BACKEND_URL_DEV
            : process.env.REACT_APP_BACKEND_URL_PRO
    }/graphql`,
})
const client = new ApolloClient({
    cache,
    link,
})

export default client
