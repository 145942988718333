import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { Container, Row } from 'react-bootstrap'
import ReactPlaceholder from 'react-placeholder/lib'
import ReactGA from 'react-ga4'

import Query from '../../components/Query'
import FAIR from '../../queries/fairs/fair'
import Error from '../../components/error'
import DisplayFair from '../../components/displayFair'
import Box from '../../components/Box'
import CenterColumn from '../../components/Box/centerColumn'
import Seo from '../../components/Seo'

const Fair = () => {
    const { slug } = useParams()

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `/fairs/${slug}` })
    })

    return (
        <Query query={FAIR} slug={slug} locale={null}>
            {({ loading, data, err }) => {
                if (loading) {
                    return (
                        <Container>
                            {[...Array(3)].map((item, index) => {
                                return (
                                    <Row key={`loading-${index}`}>
                                        <ReactPlaceholder
                                            className="py-5 px-5"
                                            showLoadingAnimation={true}
                                            type="rect"
                                            rows={1}
                                            ready={!loading}
                                        />
                                    </Row>
                                )
                            })}
                            <Box>
                                <CenterColumn>
                                    <ReactPlaceholder
                                        className=""
                                        showLoadingAnimation={true}
                                        type="text"
                                        rows={20}
                                        ready={!loading}
                                    />
                                </CenterColumn>
                            </Box>
                        </Container>
                    )
                }
                if (data) {
                    return (
                        <>
                            {data.artFairs.map((item) => {
                                return (
                                    <>
                                        <Seo
                                            title={`SGR Galería - ${item.fair}`}
                                        />
                                        <DisplayFair
                                            data={item}
                                            key={`fair-${item.id}`}
                                        />
                                    </>
                                )
                            })}
                        </>
                    )
                }
                if (err) {
                    return <Error err={err} />
                }
            }}
        </Query>
    )
}

export default Fair
