import React, { useContext, useEffect } from 'react'
import { Col } from 'react-bootstrap'
import ReactPlaceholder from 'react-placeholder/lib'
import ReactGA from 'react-ga4'

import { Context } from '../../components/Store'
import Query from '../../components/Query'
import ARTISTS from '../../queries/artist/artists'
import Gridbox from '../../components/gridBox'
import Error from '../../components/error'
import Box from '../../components/Box'
import Seo from '../../components/Seo'
import Title from '../../components/Texts/title'

const Artists = () => {
    const [state] = useContext(Context)

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `/artists` })
    })

    return (
        <>
            <Query query={ARTISTS} slug={null} locale={state.language}>
                {({ loading, data, err }) => {
                    if (loading) {
                        return (
                            <Box>
                                {[...Array(9)].map((item, index) => {
                                    return (
                                        <Col
                                            md={4}
                                            key={`loading-${index}`}
                                            className="mb-3"
                                        >
                                            <ReactPlaceholder
                                                className="py-5 px-5 mb-4"
                                                showLoadingAnimation={true}
                                                type="rect"
                                                rows={20}
                                                ready={!loading}
                                            />
                                        </Col>
                                    )
                                })}
                            </Box>
                        )
                    }

                    if (data) {
                        return (
                            <Box>
                                <Seo
                                    title={`SGR Galería - ${
                                        state.language === 'es-CO'
                                            ? 'Artistas'
                                            : 'Artists'
                                    }`}
                                />
                                <Title
                                    text={`${
                                        state.language === 'es-CO'
                                            ? 'Artistas representados'
                                            : 'Represented Artists'
                                    }`}
                                    className="mb-3"
                                />
                                {data.represented.map((item) => {
                                    return (
                                        <Gridbox
                                            key={`artist-${item.id}`}
                                            data={{
                                                type: 'artist',
                                                content: item,
                                            }}
                                            span={4}
                                            to="/artists"
                                            className="mb-5"
                                        />
                                    )
                                })}
                                {data.collab.length > 0 ? (
                                    <>
                                        <Title
                                            text={`${
                                                state.language === 'es-CO'
                                                    ? 'Artistas en colaboración'
                                                    : 'Collaborating artists'
                                            }`}
                                            className="my-4"
                                        />
                                        {data.collab.map((item) => {
                                            return (
                                                <Gridbox
                                                    key={`artist-${item.id}`}
                                                    data={{
                                                        type: 'artist',
                                                        content: item,
                                                    }}
                                                    span={4}
                                                    to="/artists"
                                                    className="mb-5"
                                                />
                                            )
                                        })}
                                    </>
                                ) : null}
                            </Box>
                        )
                    }

                    if (err) {
                        return <Error err={err} />
                    }
                }}
            </Query>
        </>
    )
}

export default Artists
