import React, { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import {
    Home,
    Artist,
    Artists,
    ArtworkSeries,
    Exhibition,
    Exhibitions,
    Fair,
    Fairs,
    FairByYear,
    About,
    Blog,
    Post,
    NotFound,
    ExhibitionsByYear,
} from './routes'
import Store from './components/Store'
import NavBar from './components/Navbar'
import Footer from './components/Footer'
import Spacer from './components/spacer'

import './App.css'

function App() {
    useEffect(() => {
        ReactGA.initialize('G-LQQPMCB878')
    }, [])

    return (
        <Store>
            <Router>
                <NavBar />
                <Spacer />
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/artists" component={Artists} />
                    <Route exact path="/artists/:slug" component={Artist} />
                    <Route
                        exact
                        path="/artwork/:artist/:id/:year/:slug"
                        component={ArtworkSeries}
                    />
                    <Route exact path="/exhibitions" component={Exhibitions} />
                    <Route
                        exact
                        path="/exhibitions/year/:year"
                        component={ExhibitionsByYear}
                    />
                    <Route
                        exact
                        path="/exhibitions/:slug"
                        component={Exhibition}
                    />
                    <Route exact path="/fairs" component={Fairs} />
                    <Route exact path="/fairs/:slug" component={Fair} />
                    <Route
                        exact
                        path="/fairs/year/:year"
                        component={FairByYear}
                    />
                    <Route exact path="/blog" component={Blog} />
                    <Route exact path="/blog/:slug" component={Post} />
                    <Route exact path="/about" component={About} />
                    <Route exact path="/404" component={NotFound} />
                    <Route path="*" component={NotFound} />
                </Switch>
                <Footer />
            </Router>
        </Store>
    )
}

export default App
