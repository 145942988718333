import React from 'react'
import PropTypes from 'prop-types'

import insta from '../../static/img/insta.png'
import fb from '../../static/img/fb.png'
import wa from '../../static/img/wa.png'

const SocialNetwork = (props) => {
    return (
        <>
            {props.network === 'instagram' ? (
                <a
                    href={props.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={props.className}
                >
                    <img src={insta} alt={props.url} />
                </a>
            ) : null}

            {props.network === 'facebook' ? (
                <a
                    href={props.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={props.className}
                >
                    <img src={fb} alt={props.url} />
                </a>
            ) : null}

            {props.network === 'whatsapp' ? (
                <a
                    href={props.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={props.className}
                >
                    <img src={wa} alt={props.url} />
                </a>
            ) : null}
        </>
    )
}

SocialNetwork.propTypes = {
    network: PropTypes.string,
    url: PropTypes.string,
    className: PropTypes.string,
}

SocialNetwork.defaultProps = {
    network: 'instagram',
    url: 'https://instagram.com',
    className: '',
}

export default SocialNetwork
