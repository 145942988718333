import React from 'react'
import PropTypes from 'prop-types'

const ExternalLink = (props) => {
    return (
        <a
            href={props.url}
            target="_blank"
            rel="noreferrer noopener"
            className={props.className}
        >
            {/* eslint-disable-next-line react/prop-types */}
            {props.children}
        </a>
    )
}

ExternalLink.propTypes = {
    url: PropTypes.string,
    className: PropTypes.string,
}

export default ExternalLink
