import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ReactPlaceholder from 'react-placeholder/lib'
import ReactGA from 'react-ga4'

import { Context } from '../../components/Store'
import Query from '../../components/Query'
import ARTIST from '../../queries/artist/artist'
import Error from '../../components/error'
import DisplayArtist from '../../components/displayArtist'
import Box from '../../components/Box'
import CenterColumn from '../../components/Box/centerColumn'
import Seo from '../../components/Seo'

const Artist = () => {
    const [state] = useContext(Context)
    const { slug } = useParams()

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `/artists/${slug}` })
    })

    return (
        <Query query={ARTIST} slug={slug} locale={state.language}>
            {({ loading, data, err }) => {
                if (loading) {
                    return (
                        <Box>
                            <CenterColumn>
                                <ReactPlaceholder
                                    className="py-5 px-5 mb-4"
                                    showLoadingAnimation={true}
                                    type="text"
                                    rows={20}
                                    ready={!loading}
                                />
                            </CenterColumn>
                        </Box>
                    )
                }

                if (data) {
                    return (
                        <Box>
                            <CenterColumn>
                                {data.artist.map((item) => {
                                    return (
                                        <>
                                            <Seo
                                                title={`SGR Galería - ${item.name}`}
                                            />
                                            <DisplayArtist
                                                key={`artist-${item.id}`}
                                                artist={item}
                                            />
                                        </>
                                    )
                                })}
                            </CenterColumn>
                        </Box>
                    )
                }

                if (err) {
                    return <Error err={err} />
                }
            }}
        </Query>
    )
}

export default Artist
