import React from 'react'
import PropTypes from 'prop-types'
import { Image, Shimmer } from 'react-shimmer'
import { useResizeDetector } from 'react-resize-detector'
import { createUseStyles } from 'react-jss'
import buildMediaUrl from '../utils/buildMediaUrl'
import ratio from '../utils/ratio'

const useStyles = createUseStyles(() => ({
    fluid: {
        maxWidth: '100%',
        height: 'auto',
    },
}))

const ShimmerImage = ({ src, size, className }) => {
    const classes = useStyles()
    const { width, ref } = useResizeDetector()

    return (
        <div ref={ref}>
            <Image
                src={buildMediaUrl(src)}
                fallback={
                    <Shimmer
                        width={width}
                        height={ratio(size.height, size.width, width)}
                    />
                }
                NativeImgProps={{ className: `${classes.fluid} ${className}` }}
            />
        </div>
    )
}

ShimmerImage.propTypes = {
    src: PropTypes.string,
    size: PropTypes.object,
    className: PropTypes.string,
}

ShimmerImage.defaultProps = {
    size: {
        height: '405',
        width: '640',
    },
    className: '',
}

export default ShimmerImage
