import React, { useContext, useEffect } from 'react'
import { Container, Row } from 'react-bootstrap'
import ReactPlaceholder from 'react-placeholder/lib'
import ReactGA from 'react-ga4'

import { Context } from '../../components/Store'
import Query from '../../components/Query'
import ABOUT from '../../queries/about/about'
import Error from '../../components/error'
import DisplayAbout from '../../components/displayAbout'
import Box from '../../components/Box'
import CenterColumn from '../../components/Box/centerColumn'
import Seo from '../../components/Seo'

const About = () => {
    const [state] = useContext(Context)

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: `/about` })
    })

    return (
        <Query query={ABOUT} locale={state.language} slug={null}>
            {({ loading, data, err }) => {
                if (loading) {
                    return (
                        <Container>
                            {[...Array(3)].map((item, index) => {
                                return (
                                    <Row key={`loading-${index}`}>
                                        <ReactPlaceholder
                                            className="py-5 px-5"
                                            showLoadingAnimation={true}
                                            type="rect"
                                            rows={1}
                                            ready={!loading}
                                        />
                                    </Row>
                                )
                            })}
                            <Box>
                                <CenterColumn>
                                    <ReactPlaceholder
                                        className=""
                                        showLoadingAnimation={true}
                                        type="text"
                                        rows={20}
                                        ready={!loading}
                                    />
                                </CenterColumn>
                            </Box>
                        </Container>
                    )
                }
                if (data) {
                    return (
                        <>
                            <Seo
                                title={`SGR Galería - ${
                                    state.language === 'es-CO'
                                        ? 'Sobre nosotros'
                                        : 'About'
                                }`}
                                description={data.info}
                            />
                            <DisplayAbout data={data} />
                        </>
                    )
                }
                if (err) {
                    return <Error err={err} />
                }
            }}
        </Query>
    )
}

export default About
