import gql from 'graphql-tag'

const ARTIST = gql`
    query Artist($slug: String!, $locale: String!) {
        artist: artists(locale: $locale, where: { slug: $slug }) {
            id
            slug
            name
            lives_and_works
            bio
            cv {
                url
            }
            website
            instagram
            facebook
            artshop_link
            serie_de_obras(sort: "year:DESC") {
                id
                title
                slug
                year
                cover {
                    url
                    formats
                }
            }
            exhibitions {
                id
                slug
                title
            }
            art_fairs {
                id
                slug
                fair
            }
            interviews {
                id
                title
                video
            }
        }
    }
`

export default ARTIST
