import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'

import buildMediaUrl from '../../utils/buildMediaUrl'
import SocialNetwork from '../social'
import ExternalLink from '../externalLink'
import Gridbox from '../gridBox'
import Oembed from '../oembed'
import Text from '../text'
import Title from '../Texts/title'
import SubTitle from '../Texts/subtitle'
import Hr from '../hr'
import Localization from '../localization'

const useStyles = createUseStyles((theme) => ({
    right: {
        textAlign: 'right',
    },
    externalLink: {
        textDecoration: 'none',
        fontFamily: theme.fontMedium,
        fontSize: '1.3rem',
        '&:hover': {
            color: theme.textGray,
        },
    },
    primary: {
        color: theme.colorPrimary,
    },
    bold: {
        fontFamily: theme.fontBold,
    },
    regular: {
        fontFamily: theme.fontRegular,
    },
    light: {
        fontFamily: theme.fontLight,
    },
    list: {
        listStyle: 'none',
    },
    internalLink: {
        textDecoration: 'none',
        fontFamily: theme.fontRegular,
        fontSize: '1rem',
        '&:hover': {
            color: theme.textGray,
        },
    },
    artworks: {
        marginTop: '5rem',
    },
}))

const DisplayArtist = ({ artist }) => {
    const classes = useStyles()

    return (
        <>
            <Title text={artist.name} />
            <SubTitle text={artist.lives_and_works} />
            <Hr />

            {artist.bio ? <Text data={artist.bio} /> : null}

            <Row className="mt-3">
                <Col xs={12} sm={12} md={6}>
                    {artist.cv ? (
                        <ExternalLink
                            url={buildMediaUrl(artist.cv.url)}
                            className={`${classes.externalLink} ${classes.primary} me-3`}
                        >
                            <span className={`${classes.bold} me-2`}>CV</span>
                            <span className={classes.regular}>[PDF]</span>
                        </ExternalLink>
                    ) : null}

                    {artist.website ? (
                        <ExternalLink
                            url={artist.website}
                            className={`${classes.externalLink} ${classes.primary} me-2`}
                        >
                            <span className={classes.regular}>website</span>
                        </ExternalLink>
                    ) : null}
                    {artist.facebook ? (
                        <SocialNetwork
                            url={artist.facebook}
                            network="facebook"
                            className="me-2"
                        />
                    ) : null}
                    {artist.instagram ? (
                        <SocialNetwork
                            url={artist.instagram}
                            network="instagram"
                            className="me-2"
                        />
                    ) : null}
                </Col>
                <Col
                    xs={12}
                    sm={12}
                    md={6}
                    className="text-md-end text-sm-start mt-3 mt-sm-3 mt-sm-0"
                >
                    {artist.artshop_link ? (
                        <ExternalLink
                            url={artist.artshop_link}
                            className={`${classes.externalLink} ${classes.primary}`}
                        >
                            <span>Obras en el Artshop</span>
                        </ExternalLink>
                    ) : null}
                </Col>
            </Row>
            <Row className={classes.artworks}>
                {artist.serie_de_obras ? (
                    <>
                        {artist.serie_de_obras.map((artwork) => {
                            return (
                                <Gridbox
                                    key={`artwork-series-${artwork.id}`}
                                    data={{
                                        type: 'artwork',
                                        content: artwork,
                                    }}
                                    to={`/artwork/${artist.slug}/${artist.id}/${artwork.year}`}
                                    span={4}
                                    className="mb-4"
                                />
                            )
                        })}
                    </>
                ) : null}
            </Row>
            <Row className="mt-5 mb-5">
                {artist.exhibitions.length ? (
                    <Col xs={12} sm={12} md={4}>
                        <>
                            <h4
                                className={`${classes.light} ${classes.primary}`}
                            >
                                <Localization
                                    es={`Exposiciones`}
                                    en={`Exhibitions`}
                                />
                            </h4>
                            <ul className={classes.list}>
                                {artist.exhibitions.map((show) => {
                                    return (
                                        <li key={`link-show-${show.id}`}>
                                            <Link
                                                to={`/exhibitions/${show.slug}`}
                                                className={`${classes.internalLink} ${classes.primary}`}
                                            >
                                                {show.title}
                                            </Link>
                                        </li>
                                    )
                                })}
                            </ul>
                        </>
                    </Col>
                ) : null}
                {artist.art_fairs.length ? (
                    <Col xs={12} sm={12} md={4}>
                        <>
                            <h4
                                className={`${classes.light} ${classes.primary}`}
                            >
                                <Localization es={`Ferias`} en={`Fairs`} />
                            </h4>
                            <ul className={classes.list}>
                                {artist.art_fairs.map((fair) => {
                                    return (
                                        <li key={`link-show-${fair.id}`}>
                                            <Link
                                                to={`/exhibitions/${fair.slug}`}
                                                className={`${classes.internalLink} ${classes.primary}`}
                                            >
                                                {fair.fair}
                                            </Link>
                                        </li>
                                    )
                                })}
                            </ul>
                        </>
                    </Col>
                ) : null}
                {artist.interviews.length ? (
                    <Col xs={12} sm={12} md={4}>
                        <>
                            <h4
                                className={`${classes.light} ${classes.primary}`}
                            >
                                <Localization
                                    es="Entrevistas"
                                    en="Interviews"
                                />
                            </h4>
                            {artist.interviews.map((data) => {
                                return (
                                    <Oembed
                                        key={`interview-${data.id}`}
                                        data={data.video}
                                        size={{
                                            width: 200,
                                            height: 113,
                                        }}
                                    />
                                )
                            })}
                        </>
                    </Col>
                ) : null}
            </Row>
        </>
    )
}

DisplayArtist.propTypes = {
    artist: PropTypes.object,
}

export default DisplayArtist
