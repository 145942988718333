import React, { useContext } from 'react'
import ReactPlaceholder from 'react-placeholder'
import 'react-placeholder/lib/reactPlaceholder.css'
import { Navbar, Nav, Container, Row, Col } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import Query from '../Query'
import { createUseStyles } from 'react-jss'

import { Context } from '../../components/Store'
import MENU_QUERY from '../../queries/menu/menus'
import SocialNetwork from '../social'
import Error from '../error'
import logo from '../../static/img/logo-nav.png'

const useStyles = createUseStyles((theme) => ({
    navItem: {
        fontFamily: theme.fontRegular,
        fontSize: '1.2rem',
        textDecoration: 'none',
        '&:hover': {
            color: `${theme.secondaryColor} !important`,
        },
    },
    regular: {
        color: `${theme.colorPrimary} !important`,
    },
    active: {
        color: `${theme.secondaryColor} !important`,
    },
    brand: {
        height: '3.5rem',
    },
}))

const NavBar = (...props) => {
    const [state, dispatch] = useContext(Context)
    const classes = useStyles(props)
    const location = useLocation()

    const changeToES = () => {
        dispatch({ type: 'SET_LANGUAGE', payload: 'es-CO' })
    }

    const changeToEN = () => {
        dispatch({ type: 'SET_LANGUAGE', payload: 'en' })
    }

    return (
        <Query query={MENU_QUERY()} slug={null} locale={state.language}>
            {({ data, loading, err }) => {
                if (loading) {
                    return (
                        <Container
                            fluid
                            className={`${classes.brand} mt-4 ms-3`}
                        >
                            <Row>
                                <Col xs={1}>
                                    <ReactPlaceholder
                                        showLoadingAnimation={true}
                                        type="media"
                                        rows={1}
                                        ready={!loading}
                                    />
                                </Col>
                                <Col xs={10}>
                                    <ReactPlaceholder
                                        showLoadingAnimation={true}
                                        type="text"
                                        rows={1}
                                        ready={!loading}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    )
                }

                if (data) {
                    return (
                        <Navbar expand="lg" className="mt-4 ms-3">
                            <Container fluid className="d-flex align-items-end">
                                <Navbar.Brand as={Link} to="/" className="me-5">
                                    <img
                                        src={logo}
                                        alt="SGR Galería"
                                        className={classes.brand}
                                    />
                                </Navbar.Brand>
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav className="me-auto">
                                        {data.menus.map((item) => {
                                            return (
                                                <React.Fragment
                                                    key={`nav-item-${item.id}`}
                                                >
                                                    {item.external ? (
                                                        <a
                                                            href={item.url}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className={`${classes.navItem} ${classes.regular} pb-0 pt-4`}
                                                        >
                                                            {item.title}
                                                        </a>
                                                    ) : (
                                                        <Nav.Link
                                                            key={`menu-${item.id}`}
                                                            as={Link}
                                                            to={item.url}
                                                            className={`${
                                                                classes.navItem
                                                            } pb-0 pt-4 ${
                                                                location.pathname.includes(
                                                                    item.url
                                                                )
                                                                    ? classes.active
                                                                    : classes.regular
                                                            }`}
                                                        >
                                                            {item.title}
                                                        </Nav.Link>
                                                    )}
                                                </React.Fragment>
                                            )
                                        })}
                                        {state.language === 'en' ? (
                                            <Nav.Link
                                                onClick={changeToES}
                                                className={`${classes.navItem} pb-0 pt-4`}
                                            >
                                                ES
                                            </Nav.Link>
                                        ) : null}
                                        {state.language === 'es-CO' ? (
                                            <Nav.Link
                                                onClick={changeToEN}
                                                className={`${classes.navItem} pb-0 pt-4`}
                                            >
                                                EN
                                            </Nav.Link>
                                        ) : null}
                                    </Nav>
                                    <Nav className="d-flex flex-row mt-4 mt-md-0 mb-5 mb-md-0">
                                        <SocialNetwork
                                            url={data.socialMedia.instagram}
                                            network="instagram"
                                            className="me-3"
                                        />
                                        <SocialNetwork
                                            url={data.socialMedia.facebook}
                                            network="facebook"
                                            className="me-3"
                                        />
                                        {data.socialMedia.whatsapp ? (
                                            <SocialNetwork
                                                url={`https://wa.me/${data.socialMedia.whatsapp}`}
                                                network="whatsapp"
                                                className="me-5"
                                            />
                                        ) : null}
                                    </Nav>
                                </Navbar.Collapse>
                            </Container>
                        </Navbar>
                    )
                }

                if (err) {
                    return <Error err={err} />
                }
            }}
        </Query>
    )
}

export default NavBar
