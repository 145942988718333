import gql from 'graphql-tag'

const LATESTS_FAIRS = gql`
    query ArtFairs {
        dates: artFairs {
            open
        }
        artFairs(sort: "open:DESC", limit: 3) {
            id
            slug
            open
            close
            fair
            cover {
                id
                url
                formats
            }
        }
    }
`
export default LATESTS_FAIRS
