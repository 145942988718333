import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

// eslint-disable-next-line react/prop-types
const Error = ({ err }) => {
    console.log(err)
    return (
        <Container fluid>
            <Row>
                <Col className="my-auto py-5">
                    <h5>Server error! :(</h5>
                </Col>
            </Row>
        </Container>
    )
}

export default Error
