import React from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
    col: (props) => ({
        background: `url(${props.src}) center no-repeat`,
        backgroundSize: 'cover',
        height: `${props.height ? props.height : 'auto'}`,
    }),
    '@media (max-width: 576px)': {
        col: {
            height: '165px !important',
        },
    },
    '@media (min-width: 577px) and (max-width: 767px)': {
        col: {
            height: '165px',
        },
    },
})

const CardImage = (props) => {
    const classes = useStyles(props)

    return (
        <Col
            md={props.span}
            className={`${classes.col} px-0 ${props.className}`}
        ></Col>
    )
}

CardImage.propTypes = {
    src: PropTypes.string,
    span: PropTypes.number,
    className: PropTypes.string,
}

CardImage.defaultProps = {
    src: 'https://placekitten.com/1600/220',
    span: 8,
}
export default CardImage
