import React from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-bootstrap'

const CenterColumn = ({ children }) => {
    return (
        <Col
            md={{ offset: 1, span: 10 }}
            xl={{ offset: 2, span: 8 }}
            xxl={{ offset: 2, span: 8 }}
        >
            {children}
        </Col>
    )
}

CenterColumn.propTypes = {
    children: PropTypes.node,
}

export default CenterColumn
