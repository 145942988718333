import React from 'react'
import { Container } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
    spacer: {
        backgroundColor: '#000000',
        height: '2rem',
    },
})

const Spacer = () => {
    const classes = useStyles()

    return (
        <Container fluid className={classes.spacer}>
            {' '}
        </Container>
    )
}

export default Spacer
